import React, { useMemo } from 'react';

import {
  BROWSE_BASE_PATHS,
  FREE_RESUME_BUILDER_BASE_PATH,
  RESOURCES_PATHNAME,
} from '@glass/common/modules/pages/paths';
import { BASE_PATH_1_QUERY } from '@glass/common/modules/pages/queryParams';
import useFreeBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useFreeBuilderBasePath';
import usePaidBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/usePaidBuilderBasePath';

import FooterListItem from '@glass/shared/components/Layout/Footer/FooterListItem';
import FooterTitle from '@glass/shared/components/Layout/Footer/FooterTitle';
import BrowseLink from '@glass/shared/components/Link/BrowseLink';
import Link from '@glass/shared/components/Link/Link';
import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import useBrowseBasePath from '@glass/shared/components/Link/useBrowseBasePath';
import browsePathSettings from '@glass/shared/modules/content/staticVariations/resumeWords/browsePathSettings';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import useVerbWords from '@glass/shared/modules/content/staticVariations/verbWords/useVerbWords';
import { builderFirstStepParams } from '@glass/shared/modules/resumes/createBuilderFirstStepParams';

const freeTrackProps = { label: 'footer-free-resume-builder' };
const buildResumeTrackProps = { label: 'footer-build-your-resume' };

function FooterResumeList() {
  const resumeWords = useResumeWords();
  const verbWords = useVerbWords();
  const freeBuilderBasePath = useFreeBuilderBasePath();
  const paidBuilderBasePath = usePaidBuilderBasePath();
  const defaultBrowseBasePath = useBrowseBasePath();

  const freeResumeBuilderParams = useMemo(() => {
    return {
      ...builderFirstStepParams,
      [BASE_PATH_1_QUERY]: freeBuilderBasePath || FREE_RESUME_BUILDER_BASE_PATH,
    };
  }, [freeBuilderBasePath]);

  const paidResumeBuilderParams = useMemo(
    () => ({
      ...builderFirstStepParams,
      [BASE_PATH_1_QUERY]: paidBuilderBasePath,
    }),
    [paidBuilderBasePath],
  );

  const otherBrowsePaths = useMemo(
    () => BROWSE_BASE_PATHS.filter((basePath1) => basePath1 !== defaultBrowseBasePath),
    [defaultBrowseBasePath],
  );

  return (
    <>
      <FooterTitle>{process.env.NEXT_PUBLIC_BRAND_TITLE}</FooterTitle>
      <div>
        <FooterListItem>
          <Link href={RESOURCES_PATHNAME}>Resources</Link>
        </FooterListItem>
        {otherBrowsePaths.map((basePath1) => (
          <FooterListItem key={basePath1} component={BrowseLink}>
            <BrowseLink basePath1={basePath1}>
              Browse {browsePathSettings[basePath1]?.resumeWords.pluralCapitalized}
            </BrowseLink>
          </FooterListItem>
        ))}
        <FooterListItem component={ResumeBuilderLink}>
          <ResumeBuilderLink params={freeResumeBuilderParams} trackProps={freeTrackProps}>
            Free {resumeWords.abbreviatedCapitalized} {verbWords.worderCapitalized}
          </ResumeBuilderLink>
        </FooterListItem>
        <FooterListItem component={ResumeBuilderLink}>
          <ResumeBuilderLink params={paidResumeBuilderParams} trackProps={buildResumeTrackProps}>
            <b>
              {verbWords.capitalized} Your {resumeWords.abbreviatedCapitalized}
            </b>
          </ResumeBuilderLink>
        </FooterListItem>
      </div>
    </>
  );
}

export default React.memo(FooterResumeList);
