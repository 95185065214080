import React from 'react';

import Skeleton from '@glass/web/components/base/Skeleton';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()({
  inline: {
    display: 'inline-block',
  },
  center: {
    margin: 'auto',
  },
});

type Props = {
  className?: string;
  center?: boolean;
  inline?: boolean;
  width?: string | number;
  height?: string | number;
  animation?: false | 'pulse' | 'wave';
  variant?: 'text' | 'rectangular' | 'rounded' | 'circular';
};

function InlineCenterSkeleton({
  className,
  center,
  inline,
  width,
  variant,
  height,
  animation,
}: Props) {
  const { classes, cx } = useStyles();
  return (
    <Skeleton
      animation={animation}
      className={cx(className, inline && classes.inline, center && classes.center)}
      height={height}
      variant={variant}
      width={width}
    />
  );
}

export default React.memo(InlineCenterSkeleton);
