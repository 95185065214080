import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import themeColor from '@glass/shared/modules/theme/styles/themeColor';

const MS_THEME_COLOR = 'msapplication-TileColor';
const THEME_COLOR = 'theme-color';

function ThemeColorHead({ color }) {
  if (!color) {
    return null;
  }
  return (
    <Head>
      <meta key={MS_THEME_COLOR} content={color} name={MS_THEME_COLOR} />
      <meta key={THEME_COLOR} content={color} name={THEME_COLOR} />
    </Head>
  );
}

ThemeColorHead.defaultProps = {
  color: themeColor,
};

ThemeColorHead.propTypes = {
  color: PropTypes.string,
};

export default ThemeColorHead;
