import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import goldenRatio, {
  doubleInverseGoldenRatio,
  inverseGoldenRatio,
} from '@glass/common/modules/goldenRatio/goldenRatio';
import createSlug from '@glass/common/modules/url/createSlug';
import Container from '@glass/web/components/Layout/Container';
import SpinnerWithContainer from '@glass/web/components/Loading/SpinnerWithContainer';
import SafeHtml from '@glass/web/components/SafeHtml/SafeHtml';
import InlineCenterSkeleton from '@glass/web/components/Skeleton/InlineCenterSkeleton';
import Typography from '@glass/web/components/Typography/Typography';
import { SECTION_ID } from '@glass/web/modules/content/pages/contentIds';
import removeHtmlTags from '@glass/web/modules/textEditor/removeHtmlTags';
import { ALL_BREAKPOINTS, LG, MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER_LARGE } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

import withErrorBoundary from '@glass/shared/components/Error/withErrorBoundary';
import SectionButtonContinueCta from '@glass/shared/components/Section/SectionButtonContinueCta';
import SectionSpacer, {
  BOTH_EDGE,
  BOTTOM_EDGE,
  TOP_EDGE,
} from '@glass/shared/components/Section/SectionSpacer';
import useExtendText from '@glass/shared/modules/content/useExtendText';
import alternatingGrayBackground from '@glass/shared/modules/theme/styles/alternatingGrayBackground';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const alternatingGrayBackgroundStyles = alternatingGrayBackground();

const useStyles = makeStyles()((theme, { outerSpacing, overflow }) => {
  const largeSpacing = theme.spacing(outerSpacing);
  const smallSpacing = theme.spacing(outerSpacing / goldenRatio / 2);
  return {
    root: {
      overflow,
      width: '100%',
      paddingTop: largeSpacing,
      paddingBottom: largeSpacing,
      transition: theme.transitions.create(['background', 'font']),
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      [theme.breakpoints.down(MD)]: {
        paddingTop: smallSpacing,
        paddingBottom: smallSpacing,
      },
    },
    alternatingColor: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    alternatingGrayBackground: alternatingGrayBackgroundStyles,
    noYPadding: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  };
});

const Section = React.forwardRef(
  (
    {
      id: idProp,
      title,
      subtitle,
      align,
      titleComponent,
      titleVariant,
      subtitleVariant,
      subtitleComponent,
      children,
      maxWidth,
      isNoXPadding,
      className,
      isAlternatingColor,
      spacing,
      disableCta,
      loading,
      outerSpacing,
      type,
      ctaOnClick,
      ctaText,
      ctaQueryParams,
      ctaComponent,
      ctaHref,
      ctaParams,
      noBottomSpacer,
      onClick,
      isNoYPadding,
      topSpacingFraction,
    },
    ref,
  ) => {
    const { classes, cx } = useStyles({
      spacing,
      outerSpacing: outerSpacing ?? spacing,
      topSpacingFraction,
    });
    const extendedTitle = useExtendText(title);
    const extendedSubTitle = useExtendText(subtitle);
    const id = useMemo(
      () => idProp || type || createSlug(removeHtmlTags(extendedTitle), { maxLength: 100 }),
      [idProp, type, extendedTitle],
    );
    const innerSpacing = spacing / goldenRatio;

    const isTitle = !!(extendedTitle || loading);
    const isSubTitle = !!(extendedSubTitle || loading);
    const isChildContainer = !!(children || loading);

    let edge = null;

    if (!isTitle && !isSubTitle) {
      edge = TOP_EDGE;
    }

    if (disableCta) {
      if (edge === TOP_EDGE) {
        edge = BOTH_EDGE;
      } else {
        edge = BOTTOM_EDGE;
      }
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        ref={ref}
        className={cx(
          classes.root,
          isAlternatingColor && classes.alternatingColor,
          isAlternatingColor && classes.alternatingGrayBackground,
          isNoYPadding && classes.noYPadding,
          className,
        )}
        id={id}
        onClick={onClick}
      >
        <Container isNoYPadding isNoXPadding={isNoXPadding} maxWidth={maxWidth}>
          <Container isNoXPadding maxWidth={LG}>
            {isTitle ? (
              <Typography
                align={align}
                component={titleComponent}
                gutterBottom={!!extendedSubTitle}
                variant={titleVariant}
              >
                <SafeHtml component="span">
                  {extendedTitle || (
                    <InlineCenterSkeleton center width={`${inverseGoldenRatio}%`} />
                  )}
                </SafeHtml>
              </Typography>
            ) : null}
            {isSubTitle ? (
              <Typography
                align={align}
                component={subtitleComponent}
                gutterBottom={!!children}
                variant={subtitleVariant}
              >
                <SafeHtml component="span">
                  {extendedSubTitle || (
                    <InlineCenterSkeleton center width={`${doubleInverseGoldenRatio}%`} />
                  )}
                </SafeHtml>
              </Typography>
            ) : null}
          </Container>
          {isChildContainer ? (
            <SectionSpacer edge={edge} spacing={noBottomSpacer ? 0 : innerSpacing}>
              {children || (
                <SpinnerWithContainer>
                  <div />
                </SpinnerWithContainer>
              )}
            </SectionSpacer>
          ) : null}
          {!disableCta ? (
            <SectionSpacer edge={BOTTOM_EDGE} spacing={innerSpacing}>
              <SectionButtonContinueCta
                component={ctaComponent}
                ctaQueryParams={ctaQueryParams}
                disabled={loading}
                href={ctaHref}
                params={ctaParams}
                onClick={ctaOnClick}
              >
                {ctaText}
              </SectionButtonContinueCta>
            </SectionSpacer>
          ) : null}
        </Container>
      </div>
    );
  },
);

Section.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  ctaComponent: PropTypes.elementType,
  ctaHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ctaOnClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  ctaParams: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  ctaQueryParams: PropTypes.object,
  ctaText: PropTypes.string,
  disableCta: PropTypes.bool,
  id: PropTypes.string,
  isAlternatingColor: PropTypes.bool,
  isNoXPadding: PropTypes.bool,
  isNoYPadding: PropTypes.bool,
  loading: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
  noBottomSpacer: PropTypes.bool,
  onClick: PropTypes.func,
  outerSpacing: PropTypes.number,
  spacing: PropTypes.number,
  subtitle: PropTypes.node,
  subtitleComponent: PropTypes.elementType,
  subtitleVariant: PropTypes.string,
  title: PropTypes.node,
  titleComponent: PropTypes.elementType,
  titleVariant: PropTypes.string,
  topSpacingFraction: PropTypes.number,
  type: PropTypes.string,
};

Section.defaultProps = {
  spacing: DEFAULT_SPACING,
  align: CENTER_LARGE,
  titleVariant: 'h2',
  subtitleVariant: 'h5',
  isAlternatingColor: true,
  noBottomSpacer: false,
};

Section.displayName = 'Section';

export default withTrackingContext(withErrorBoundary(Section), SECTION_ID);
