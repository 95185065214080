import React from 'react';

import SpinnerWithContainer from '@glass/web/components/Loading/SpinnerWithContainer';

import { SIGNIN_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import { CHILDREN_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';

const setAuthLoadingAction = (options = {}) =>
  promptAuthAction({
    ...options,
    inDialog: true,
    actionType: SIGNIN_ACTION,
    view: CHILDREN_AUTH_VIEW,
    children: <SpinnerWithContainer />,
  });

export default setAuthLoadingAction;
