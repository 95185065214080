import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsSnackbarOpen } from '@glass/web/modules/globalSnackbar';
import useIsDocumentReadyPriorityLoadedIdle from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdle';

import DynamicGlobalSnackbar from '@glass/shared/components/Global/Snackbar/DynamicGlobalSnackbar';

function GlobalSnackbarContainer() {
  const isDocumentReadyPriorityLoadedIdle = useIsDocumentReadyPriorityLoadedIdle();
  const isSnackbarOpen = useSelector(selectIsSnackbarOpen);
  if (!isSnackbarOpen && !isDocumentReadyPriorityLoadedIdle) {
    return null;
  }
  return <DynamicGlobalSnackbar loading={!!isSnackbarOpen} open={isSnackbarOpen} />;
}

export default React.memo(GlobalSnackbarContainer);
