import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PROFILE_PATHNAME,
  RESOURCES_PATHNAME,
  SIGNIN_PATHNAME,
} from '@glass/common/modules/pages/paths';
import { HEADER_RESUME_TEMPLATES_BUTTON } from '@glass/common/modules/tests/testIds';
import createSlug from '@glass/common/modules/url/createSlug';
import ButtonIcon from '@glass/web/components/Button/ButtonIcon';
import Container from '@glass/web/components/Layout/Container';
import useBlockClick from '@glass/web/components/Link/useBlockClick';
import AppBar from '@glass/web/components/base/AppBar';
import Fade from '@glass/web/components/base/Fade';
import IconButton from '@glass/web/components/base/IconButton';
import Toolbar from '@glass/web/components/base/Toolbar';
import Grid from '@glass/web/components/base/Unstable_Grid2';
import AccountCircle from '@glass/web/components/base/icons/AccountCircle';
import ExpandMoreRounded from '@glass/web/components/base/icons/ExpandMoreRounded';
import MenuIcon from '@glass/web/components/base/icons/Menu';
import { selectHeaderCtaOpen } from '@glass/web/modules/app';
import openHeaderCta from '@glass/web/modules/app/openHeaderCta';
import { selectIsUser } from '@glass/web/modules/auth';
import { HEADER_ID } from '@glass/web/modules/content/pages/contentIds';
import AbortSubmissionError from '@glass/web/modules/error/AbortSubmissionError';
import useIsDocumentReadyPriorityLoadedIdle from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdle';
import { ALL_BREAKPOINTS, DEFAULT_BP, LG, MD, SM } from '@glass/web/modules/theme/breakpointKeys';
import { darken } from '@glass/web/modules/theme/colors';
import { CENTER, RIGHT } from '@glass/web/modules/theme/constants';
import { FLEX, SPACE_BETWEEN } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import rgbaToHex from '@glass/web/modules/theme/rgbaToHex';
import darkBackdrop from '@glass/web/modules/theme/styles/darkBackdrop';
import useTheme from '@glass/web/modules/theme/useTheme';

import Button from '@glass/shared/components/Button/Button';
import ThemeColorHead from '@glass/shared/components/Head/ThemeColorHead';
import HeaderCta from '@glass/shared/components/Layout/Header/HeaderCta';
import HeaderSearchBar from '@glass/shared/components/Layout/Header/HeaderSearchBar';
import HeaderSpacer from '@glass/shared/components/Layout/Header/HeaderSpacer';
import HeaderLogo from '@glass/shared/components/Layout/Header/LogoLink';
import {
  INDUSTRIES_CONTENT,
  RESOURCE_CONTENT,
} from '@glass/shared/components/Layout/Header/headerContentTypes';
import Link from '@glass/shared/components/Link/Link';
import Rotate from '@glass/shared/components/Rotate/Rotate';
import ensureUserAction from '@glass/shared/modules/authForm/actions/ensureUserAction';
import { TEMPLATES_CONTENT_WORD } from '@glass/shared/modules/content/staticVariations/contentWords/contentWordVariations';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import usePageData from '@glass/shared/modules/pageData/usePageData';
import defaultThemeColor from '@glass/shared/modules/theme/styles/themeColor';
import useTracking from '@glass/shared/modules/tracking/useTracking';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';
import { selectUserContentShowContentWidget } from '@glass/shared/modules/userContent';

const DynamicHeaderTopDrawer = dynamic(() => import('./HeaderTopDrawer'), {
  ssr: false,
});

const DynamicHeaderSideDrawer = dynamic(() => import('./HeaderSideDrawer'), {
  ssr: false,
});

const DynamicResumeBuilderWidget = dynamic(
  () => import('./ResumeBuilderWidget/ResumeBuilderWidget'),
  {
    ssr: false,
  },
);

const useStyles = makeStyles()((theme, { backgroundColor, backdropFilter, backgroundImage }) => ({
  root: {
    backgroundColor,
    backdropFilter,
    backgroundImage,
  },
  expansion: {
    margin: 'auto',
  },
  buttonContainer: {
    flexGrow: 1,
    display: FLEX,
    justifyContent: CENTER,
    alignItems: CENTER,
    gap: theme.spacing(2),
  },
  divContainer: {
    display: FLEX,
    justifyContent: 'flex-end',
    alignItems: CENTER,
    gap: theme.spacing(1),
  },
  searchBar: {
    display: FLEX,
    gap: theme.spacing(0.5),
  },
  profileIcon: {
    transition: theme.transitions.create('color'),
  },
  profileActive: {
    color: theme.palette.accent[300],
  },
  searchButton: {
    [theme.breakpoints.down(SM)]: {
      display: 'none',
    },
  },
  smButton: {
    '@media (max-width: 700px)': {
      display: 'none',
    },
  },
  buttonSource: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topDrawer: {
    zIndex: `${theme.zIndex.appBar - 1}!important`,
  },
  hiddenLgDn: {
    [theme.breakpoints.down(LG)]: {
      display: 'none',
    },
  },
  hiddenSmUp: {
    [theme.breakpoints.up(SM)]: {
      display: 'none',
    },
  },
  hiddenMdDn: {
    [theme.breakpoints.down(MD)]: {
      display: 'none',
    },
  },
  hiddenSmDn: {
    [theme.breakpoints.down(SM)]: {
      display: 'none',
    },
  },
}));

const TRANSPARENT_THEME_COLOR = '#223267';

const FIXED_POSITION = 'fixed';

function Header({
  transparent,
  maxWidth,
  minimal,
  search,
  hideAuth,
  tintOpacity,
  tintColor,
  tintDarkness,
  elevation,
  position,
  matchTheme,
  addFixedHeaderSpacing,
}) {
  const theme = useTheme();
  const isDocumentReadyPriorityLoadedIdle = useIsDocumentReadyPriorityLoadedIdle();
  const isResumeBuilderWidgetOpen = useSelector(selectUserContentShowContentWidget);

  const isUser = useSelector(selectIsUser);
  const { pathname } = useRouter();
  const dispatch = useDispatch();
  const tracking = useTracking();
  const [topDrawerOpen, setTopDrawerOpen] = useState(false);
  const [topDrawerContent, setTopDrawerContent] = useState(INDUSTRIES_CONTENT);
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const handleSideDrawerToggle = useCallback(
    () => setSideDrawerOpen(!sideDrawerOpen),
    [sideDrawerOpen],
  );
  const headerCtaOpen = useSelector(selectHeaderCtaOpen);
  const handleSideDrawerClose = useCallback(() => setSideDrawerOpen(false), []);

  const backgroundStyles = useMemo(() => {
    if (transparent) {
      const backdropStyles = darkBackdrop(theme, {
        darkness: tintDarkness,
        tintColor,
        opacity: tintOpacity,
      });
      return {
        ...backdropStyles,
        backgroundImage: 'none!important',
      };
    }
    return {
      backgroundColor: defaultThemeColor,
    };
  }, [transparent, theme, tintDarkness, tintColor, tintOpacity]);

  const { classes, cx } = useStyles(backgroundStyles);

  const handleTopDrawerOpen = useCallback(
    (content) => () => {
      if (content !== topDrawerContent) {
        setTopDrawerContent(content);
        setTopDrawerOpen(true);
      } else {
        setTopDrawerOpen(!topDrawerOpen);
      }
    },
    [topDrawerContent, topDrawerOpen],
  );

  const handleDrawerClose = useCallback(() => {
    setTopDrawerOpen(false);
    setSideDrawerOpen(false);
  }, []);

  const handleAuthClick = useCallback(
    (evt) => {
      if (!isUser) {
        evt.preventDefault();
        dispatch(ensureUserAction({ next: PROFILE_PATHNAME })).catch((err) => {
          if (err instanceof AbortSubmissionError) {
            return;
          }
          tracking.exception(err);
        });
      }
    },
    [isUser, dispatch, tracking],
  );

  const handleResourcesOpen = useMemo(
    () => handleTopDrawerOpen(RESOURCE_CONTENT),
    [handleTopDrawerOpen],
  );
  const handleIndustriesOpen = useMemo(
    () => handleTopDrawerOpen(INDUSTRIES_CONTENT),
    [handleTopDrawerOpen],
  );

  const { handleClick: handleBlockClick } = useBlockClick(handleDrawerClose);
  const { handleClick: handleBlockAuthClick } = useBlockClick(handleAuthClick);
  const { handleClick: handleResourcesOpenBlock } = useBlockClick(handleResourcesOpen);
  const { handleClick: handleIndustriesOpenBlock } = useBlockClick(handleIndustriesOpen);
  const { handleClick: handleSideDrawerToggleBlock } = useBlockClick(handleSideDrawerToggle);

  const resumeWords = useResumeWords();

  const themeColor = useMemo(() => {
    if (matchTheme && backgroundStyles?.backgroundColor?.match(/^rgba/)) {
      return darken(rgbaToHex(backgroundStyles.backgroundColor), 0.22);
    }
    if (transparent) {
      return TRANSPARENT_THEME_COLOR;
    }
    return defaultThemeColor;
  }, [transparent, backgroundStyles.backgroundColor, matchTheme]);

  const headerCta = <HeaderCta />;
  const { templatesBasePath, isTemplateLandingPage } = usePageData();

  const templateLandingHref = useMemo(() => {
    if (resumeWords.singular) {
      return `/${createSlug(resumeWords.singular)}-${TEMPLATES_CONTENT_WORD}`;
    }
    return templatesBasePath;
  }, [resumeWords.singular, templatesBasePath]);

  useEffect(() => {
    dispatch(openHeaderCta(true));
  }, [dispatch]);

  return (
    <>
      <ThemeColorHead color={themeColor} />
      {addFixedHeaderSpacing && position === FIXED_POSITION ? <HeaderSpacer /> : null}
      <AppBar className={classes.root} elevation={elevation} position={position}>
        <Container isNoYPadding maxWidth={maxWidth}>
          <Toolbar
            disableGutters
            sx={{
              justifyContent: SPACE_BETWEEN,
            }}
          >
            <HeaderLogo onClick={handleBlockClick} />
            {!minimal ? (
              <div className={classes.buttonContainer}>
                {!isTemplateLandingPage ? (
                  <Button
                    className={classes.smButton}
                    color="inherit"
                    component={Link}
                    data-test-id={HEADER_RESUME_TEMPLATES_BUTTON}
                    href={templateLandingHref}
                    size="medium"
                    onClick={handleBlockClick}
                  >
                    {resumeWords.abbreviatedCapitalized} {TEMPLATES_CONTENT_WORD}
                  </Button>
                ) : null}
                {pathname !== RESOURCES_PATHNAME ? (
                  <div className={classes.hiddenLgDn}>
                    <Button
                      className={classes.buttonSource}
                      color="inherit"
                      size="medium"
                      onClick={handleResourcesOpenBlock}
                    >
                      Resources
                      <Rotate
                        component={ButtonIcon}
                        icon={ExpandMoreRounded}
                        position={RIGHT}
                        rotate={topDrawerOpen && topDrawerContent === RESOURCE_CONTENT ? 180 : 0}
                      />
                    </Button>
                  </div>
                ) : null}

                <div className={isTemplateLandingPage ? classes.hiddenSmDn : classes.hiddenMdDn}>
                  <Button
                    className={classes.buttonSource}
                    color="inherit"
                    size="medium"
                    onClick={handleIndustriesOpenBlock}
                  >
                    Browse {resumeWords.pluralAbbreviatedCapitalized}
                    <Rotate
                      component={ButtonIcon}
                      icon={ExpandMoreRounded}
                      position={RIGHT}
                      rotate={topDrawerOpen && topDrawerContent === INDUSTRIES_CONTENT ? 180 : 0}
                    />
                  </Button>
                </div>
              </div>
            ) : null}
            <div className={classes.divContainer}>
              {search && !minimal ? (
                <>
                  <Fade appear={false} in={!!search && headerCtaOpen}>
                    <div className={classes.searchButton}>
                      <HeaderSearchBar enable={headerCtaOpen} sibling={headerCta} />
                    </div>
                  </Fade>
                  <div className={classes.hiddenSmUp}>{headerCta}</div>
                </>
              ) : null}
              {!search && !minimal ? <Grid>{headerCta}</Grid> : null}
              {!hideAuth ? (
                <IconButton
                  className={cx(classes.profileIcon, isUser ? classes.profileActive : null)}
                  color="inherit"
                  component={Link}
                  edge="end"
                  href={isUser ? PROFILE_PATHNAME : SIGNIN_PATHNAME}
                  size="large"
                  onClick={handleBlockAuthClick}
                >
                  <AccountCircle />
                </IconButton>
              ) : null}
              {!minimal ? (
                <IconButton
                  aria-label="open drawer"
                  className={classes.menuButton}
                  color="inherit"
                  edge="end"
                  size="large"
                  onClick={handleSideDrawerToggleBlock}
                >
                  <MenuIcon />
                </IconButton>
              ) : null}
            </div>
          </Toolbar>
        </Container>
      </AppBar>
      {!minimal && (isDocumentReadyPriorityLoadedIdle || topDrawerOpen) ? (
        <DynamicHeaderTopDrawer
          className={classes.topDrawer}
          content={topDrawerContent}
          open={topDrawerOpen}
          onClose={handleDrawerClose}
        />
      ) : null}
      {!minimal && (isDocumentReadyPriorityLoadedIdle || sideDrawerOpen) ? (
        <DynamicHeaderSideDrawer
          open={sideDrawerOpen}
          onAuthClick={handleBlockAuthClick}
          onClose={handleSideDrawerClose}
        />
      ) : null}
      {!minimal && (isDocumentReadyPriorityLoadedIdle || isResumeBuilderWidgetOpen) ? (
        <DynamicResumeBuilderWidget open={!!isResumeBuilderWidgetOpen} />
      ) : null}
    </>
  );
}

Header.propTypes = {
  addFixedHeaderSpacing: PropTypes.bool,
  elevation: PropTypes.number,
  hideAuth: PropTypes.bool,
  matchTheme: PropTypes.bool,
  maxWidth: PropTypes.oneOf(ALL_BREAKPOINTS),
  minimal: PropTypes.bool,
  position: PropTypes.string,
  search: PropTypes.bool,
  tintColor: PropTypes.string,
  tintDarkness: PropTypes.number,
  tintOpacity: PropTypes.number,
  transparent: PropTypes.bool,
};

Header.defaultProps = {
  position: FIXED_POSITION,
  transparent: true,
  maxWidth: DEFAULT_BP,
  minimal: false,
  search: true,
  elevation: 0,
  hideAuth: false,
  tintDarkness: 0.65,
  tintOpacity: 0.5,
  tintColor: defaultThemeColor,
  addFixedHeaderSpacing: false,
};

export default React.memo(withTrackingContext(Header, HEADER_ID));
