import { useCallback, useEffect, useRef, useState } from 'react';

const useTimeout = (
  callback: () => void,
  delay: number | null,
  { startImmediately = true, autoRestart = false } = {},
) => {
  const [isActive, setIsActive] = useState(startImmediately);
  const callbackRef = useRef<TimerHandler | undefined>();
  const timerRef = useRef<number | undefined>();

  const set = useCallback(() => {
    if (delay !== null && !Number.isNaN(delay) && delay >= 0) {
      timerRef.current =
        typeof callbackRef.current === 'function'
          ? setTimeout(callbackRef.current, delay)
          : undefined;
    }
  }, [delay]);
  const clear = useCallback(() => clearTimeout(timerRef.current), []);

  useEffect(() => {
    callbackRef.current = () => {
      if (typeof callback === 'function') {
        callback();
      }
      if (autoRestart) {
        if (!isActive) {
          setIsActive(true);
        } else {
          set();
        }
      } else {
        setIsActive(false);
      }
    };
  }, [set, autoRestart, isActive, callback]);

  const resetTimer = useCallback(() => {
    clear();
    set();
  }, [clear, set]);

  const clearTimer = useCallback(() => setIsActive(false), []);
  const startTimer = useCallback(() => setIsActive(true), []);

  useEffect(() => {
    if (isActive) {
      set();
    }
    return clear;
  }, [isActive, set, clear]);

  return { clearTimer, resetTimer, startTimer };
};

export default useTimeout;
