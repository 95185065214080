import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { INDUSTRY_PATHNAME } from '@glass/common/modules/pages/paths';
import { BASE_PATH_1_QUERY, BASE_PATH_2_QUERY } from '@glass/common/modules/pages/queryParams';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import Link from '@glass/shared/components/Link/Link';
import useBrowseBasePath from '@glass/shared/components/Link/useBrowseBasePath';
import industryPropTypes from '@glass/shared/modules/jobTitles/industryPropTypes';

const IndustryLink = React.forwardRef(
  (
    {
      className,
      [BASE_PATH_1_QUERY]: basePath1Prop,
      industrySlug,
      jobTitleSlug,
      industries,
      template,
      children,
      ...otherProps
    },
    ref,
  ) => {
    const basePath1 = useBrowseBasePath(basePath1Prop);
    const href = useMemo(
      () => ({
        pathname: INDUSTRY_PATHNAME,
        query: removeEmptyKeys({
          [BASE_PATH_1_QUERY]: basePath1,
          [BASE_PATH_2_QUERY]: industrySlug,
          template,
        }),
      }),
      [basePath1, industrySlug, template],
    );
    return (
      <Link {...otherProps} ref={ref} className={className} href={href}>
        {children}
      </Link>
    );
  },
);

IndustryLink.propTypes = {
  className: PropTypes.string,
  industries: PropTypes.arrayOf(PropTypes.shape(industryPropTypes)),
  industrySlug: PropTypes.string,
  jobTitleSlug: PropTypes.string,
  seed: PropTypes.string,
  template: PropTypes.string,
};

IndustryLink.displayName = 'IndustryLink';

export default IndustryLink;
