import Router from 'next/router';

import { selectUser } from '@glass/web/modules/auth';
import AbortSubmissionError from '@glass/web/modules/error/AbortSubmissionError';
import { selectGlobalDialogProps } from '@glass/web/modules/globalDialog';
import resetGlobalDialogAction from '@glass/web/modules/globalDialog/resetGlobalDialogAction';
import selectIsAuthReady from '@glass/web/modules/userConfig/selectors/selectIsAuthReady';

import { CREATE_ACCOUNT_ACTION } from '@glass/shared/modules/auth/authActionTypes';
import createEmailSignupAction from '@glass/shared/modules/authForm/actions/createEmailSignupAction';
import promptAuthAction from '@glass/shared/modules/authForm/actions/promptAuthAction';
import setAuthLoadingAction from '@glass/shared/modules/authForm/actions/setAuthLoadingAction';
import setAuthPropsAction from '@glass/shared/modules/authForm/actions/setAuthPropsAction';
import { EMAIL_AUTH_VIEW } from '@glass/shared/modules/authForm/authViews';
import extendAuthProps from '@glass/shared/modules/authForm/extendAuthProps';
import waitForCondition from '@glass/shared/modules/userConfig/waitForCondition';

const ensureUserAction =
  ({
    email,
    profile,
    requirePassword = true,
    next,
    title,
    message,
    actionType = CREATE_ACCOUNT_ACTION,
    availableActions,
    onAuth,
    onExit,
  } = {}) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const handleNext = async (result) => {
        if (next) {
          await Router.push(next);
        }
        return result;
      };

      const handleClose = () => {
        if (selectGlobalDialogProps(getState())?.open) {
          dispatch(resetGlobalDialogAction());
          dispatch(setAuthPropsAction());
        }
      };

      const handleAuth = async (result) => {
        if (typeof onAuth === 'function') {
          await Promise.resolve(onAuth(result));
        }
        return handleNext(result).then(handleClose).then(resolve);
      };

      const initUser = selectUser(getState());

      if (initUser) {
        return handleAuth(initUser);
      }

      const conditionFn = () => selectIsAuthReady(getState());

      return waitForCondition(conditionFn, {
        onWait: () => dispatch(setAuthLoadingAction()),
      }).then(() => {
        const user = selectUser(getState());
        if (user) {
          return handleAuth(user);
        }

        const handleExit = async () => {
          if (typeof onExit === 'function') {
            await Promise.resolve(onExit());
          }
          handleClose();
          return reject(new AbortSubmissionError());
        };

        const authProps = {
          requirePassword,
          inDialog: true,
          onAuth: handleAuth,
          onExit: handleExit,
          next,
          title,
          message,
          actionType,
          availableActions,
        };

        if (email) {
          // do not prompt but setup options in case a modal is needed, they will be merged in future prompts
          dispatch(extendAuthProps(authProps));
          return dispatch(createEmailSignupAction(authProps)({ email, profile }));
        }

        return dispatch(promptAuthAction({ ...authProps, view: EMAIL_AUTH_VIEW }));
      });
    });

export default ensureUserAction;
