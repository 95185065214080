import { useMemo } from 'react';

import useClientReadyRouter from '@glass/web/modules/router/useClientReadyRouter';

import { createContentSeed } from '@glass/shared/components/ResumeBuilder/templates/createContentSeed';

const usePageSeed = (seedInput = '') => {
  const { asPath, isClientReady } = useClientReadyRouter();

  return useMemo(() => {
    if (!isClientReady) {
      return null;
    }
    const infiniteSeed = asPath + seedInput;
    return createContentSeed({ fallbackId: infiniteSeed });
  }, [asPath, isClientReady, seedInput]);
};

export default usePageSeed;
