import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const VIEWPORT_NAME = 'viewport';

function ViewportHead({ content }) {
  return (
    <Head>
      <meta key={VIEWPORT_NAME} content={content} name={VIEWPORT_NAME} />
    </Head>
  );
}

ViewportHead.propTypes = {
  content: PropTypes.string,
};

ViewportHead.defaultProps = {
  content:
    'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=5, shrink-to-fit=no',
};

export default React.memo(ViewportHead);
