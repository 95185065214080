import PropTypes from 'prop-types';
import React from 'react';

import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()((theme, { rotate }) => ({
  root: {
    transition: theme.transitions.create('transform'),
    transform: `rotate(${rotate}deg)`,
  },
}));

function Rotate({ className, component: Component, rotate, icon, position }) {
  const { classes, cx } = useStyles({ rotate });
  return <Component className={cx(className, classes.root)} icon={icon} position={position} />;
}

Rotate.propTypes = {
  className: PropTypes.string,
  component: PropTypes.elementType,
  icon: PropTypes.elementType,
  position: PropTypes.string,
  rotate: PropTypes.number,
};

Rotate.defaultProps = {
  rotate: 0,
  component: 'div',
};

export default React.memo(Rotate);
