import { OPEN_HEADER_CTA } from '@glass/web/modules/app';

const openHeaderCta = (headerCtaOpen = true) => ({
  type: OPEN_HEADER_CTA,
  payload: {
    headerCtaOpen,
  },
});

export default openHeaderCta;
