import type { IdleCallbackOptions } from '@glass/web/modules/loading/useIdleCallback';
import useIsIdleLoaded from '@glass/web/modules/loading/useIsIdleLoaded';
import { useIsPriorityLoaded } from '@glass/web/modules/loading/withPriorityEvents';

// used for process intensive lazy loading (new script, react code)
const useIsPriorityLoadedIdle = ({ enabled = true, timeout }: IdleCallbackOptions = {}) => {
  const isPriorityAssetsLoaded = useIsPriorityLoaded();
  return useIsIdleLoaded({ enabled: Boolean(enabled && isPriorityAssetsLoaded), timeout });
};

export default useIsPriorityLoadedIdle;
