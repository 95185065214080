import type { IdleCallbackOptions } from '@glass/web/modules/loading/useIdleCallback';
import useInView, { InViewOptions } from '@glass/web/modules/loading/useInView';
import useIsDocumentReadyPriorityLoadedIdle from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdle';

const useIsDocumentReadyPriorityLoadedIdleInView = (
  inViewOptions?: InViewOptions,
  idleOptions?: IdleCallbackOptions,
) => {
  const { triggerOnce: isTriggerOnce = true, rootMargin = '400px 0px' } = inViewOptions || {};
  const { ref: refInView, inView: isInView } = useInView({
    ...inViewOptions,
    triggerOnce: isTriggerOnce,
    rootMargin,
  });
  const isDocumentReadyPriorityLoadedIdle = useIsDocumentReadyPriorityLoadedIdle(idleOptions);
  return {
    refInView,
    isDocumentReadyPriorityLoadedIdleInView: isDocumentReadyPriorityLoadedIdle || isInView,
    isDocumentReadyPriorityLoadedIdle,
    isInView,
  };
};

export default useIsDocumentReadyPriorityLoadedIdleInView;
