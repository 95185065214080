import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@glass/web/components/base/Typography';
import { CENTER } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme) => ({
  root: {
    textAlign: CENTER,
    marginBottom: theme.spacing(EXTRA_SMALL_SPACING / 4),
  },
}));

export default function FooterListItem({ children }) {
  const { classes } = useStyles();

  return <Typography className={classes.root}>{children}</Typography>;
}

FooterListItem.propTypes = {
  children: PropTypes.node,
};
