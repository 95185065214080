import React from 'react';

const SRC = `https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM_ID}`;

function GoogleTagManagerBodyScript() {
  return process.env.NEXT_PUBLIC_GTM_ID ? (
    <noscript>
      <iframe
        height="0"
        src={SRC}
        style={{ display: 'none', visibility: 'hidden' }}
        title="tagmanager"
        width="0"
      />
    </noscript>
  ) : null;
}

export default React.memo(GoogleTagManagerBodyScript);
