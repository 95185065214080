import React, { useCallback, useEffect, useRef } from 'react';

import SearchAutocomplete, {
  SearchAutocompleteProps,
} from '@glass/web/components/Autocomplete/SearchAutocomplete';
import Suggestion from '@glass/web/modules/types/SuggestionInterface';

export interface ControlledSearchAutocompleteProps<T = string>
  extends Omit<SearchAutocompleteProps<T>, 'onChange'> {
  onChange?: (value: string) => void;
  queryOptions?: SearchAutocompleteProps<T>['queryOptions'];
}

function ControlledSearchAutocomplete<T extends Suggestion>({
  autoFocus,
  value,
  onChange,
  query,
  dataKey,
  onFocus,
  onBlur,
  onSelectChange,
  textFieldProps,
  textFieldClassName,
  queryOptions,
  className,
  disabled,
  delay,
  getOptionLabel,
  loading,
  maxResults,
}: ControlledSearchAutocompleteProps<T>) {
  const inputRef = useRef<HTMLInputElement>();

  const handleChange: NonNullable<SearchAutocompleteProps<T>['onChange']> = useCallback(
    (e) => {
      onChange?.(e.target.value);
    },
    [onChange],
  );

  // this isn't good
  useEffect(() => {
    if (inputRef?.current?.value) {
      onChange?.(inputRef.current.value);
    }
  }, [onChange, onFocus]);

  return (
    <SearchAutocomplete
      autoFocus={autoFocus}
      dataKey={dataKey}
      getOptionLabel={getOptionLabel}
      query={query}
      textFieldProps={textFieldProps}
      textFieldClassName={textFieldClassName}
      className={className}
      maxResults={maxResults}
      loading={loading}
      disabled={disabled}
      delay={delay}
      value={value}
      inputRef={inputRef}
      queryOptions={queryOptions}
      onSelectChange={onSelectChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onChange={handleChange}
    />
  );
}

export default ControlledSearchAutocomplete;
