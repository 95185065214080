import React from 'react';

import Box, { BoxProps } from '@glass/web/components/base/Box';
import { INLINE, LEFT, RIGHT } from '@glass/web/modules/theme/constants';

type ButtonIconProps = {
  className?: string;
  display?: string;
  fontSize?: number;
  icon: React.ElementType;
  margin?: BoxProps['margin'];
  position?: typeof LEFT | typeof RIGHT;
};

function ButtonIcon({
  position = LEFT,
  margin = 1,
  icon,
  className,
  display = INLINE,
  fontSize,
}: ButtonIconProps) {
  if (!icon) {
    return null;
  }

  return (
    <Box
      className={className}
      component={icon}
      display={display}
      fontSize={fontSize}
      ml={position === RIGHT ? margin : undefined}
      mr={position === LEFT ? margin : undefined}
    />
  );
}

export default React.memo(ButtonIcon);
