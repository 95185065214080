import { matchSorter } from 'match-sorter';

import { splitString } from '@glass/web/modules/autocomplete/useHighlightStringRegex';

interface FilterOptionsProps {
  inputValue: string;
}

interface CreateFilterOptionsProps {
  keys: string[];
}

export const defaultFilterKeys = ['name'];

const createFilterOptions =
  ({ keys }: CreateFilterOptionsProps) =>
  <T extends unknown>(options: T[], { inputValue }: FilterOptionsProps) => {
    if (!inputValue || typeof inputValue !== 'string') return options;
    const regex = splitString(inputValue);

    const terms = inputValue.trim().split(regex);
    const filteredOptions = terms.reduceRight(
      (options, inputValue) =>
        matchSorter(options, inputValue, {
          keys,
          // required to prevent front-end sorting
          baseSort: () => 0,
        }),
      options,
    );

    return filteredOptions as T[];
  };

export default createFilterOptions;
