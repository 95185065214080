// todo: remove lodash on client
import memoize from 'lodash/memoize';

import { ONE_DAY_MS } from '@glass/common/modules/dates/constants';

import generateRangeTimeSeed from '@glass/shared/modules/random/generateRangeTimeSeed';

// changing this will reset all images
const generateContentTimeSeed = memoize((id, min = 270 * ONE_DAY_MS, max = 540 * ONE_DAY_MS) =>
  generateRangeTimeSeed(min, max, id),
);

export default generateContentTimeSeed;
