import type { IdleCallbackOptions } from '@glass/web/modules/loading/useIdleCallback';
import useIsDocumentReadyState from '@glass/web/modules/loading/useIsDocumentReadyState';
import useIsIdleLoaded from '@glass/web/modules/loading/useIsIdleLoaded';
import { useIsPriorityLoaded } from '@glass/web/modules/loading/withPriorityEvents';

// used for process intensive lazy loading (new script, react code)
const useIsDocumentReadyPriorityLoadedIdle = ({
  enabled = true,
  timeout,
}: IdleCallbackOptions = {}) => {
  const isDocumentReady = useIsDocumentReadyState();
  const isPriorityAssetsLoaded = useIsPriorityLoaded();
  return useIsIdleLoaded({
    enabled: Boolean(enabled && isPriorityAssetsLoaded && isDocumentReady),
    timeout,
  });
};

export default useIsDocumentReadyPriorityLoadedIdle;
