import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import React from 'react';

import useIsAssignmentReady from '@glass/common/modules/assignments/useIsAssignmentReady';
import InlineCenterSkeleton from '@glass/web/components/Skeleton/InlineCenterSkeleton';
import useLoadingResources from '@glass/web/modules/resources/useLoadingResources';

import FooterIndustryListItem from '@glass/shared/components/Layout/Footer/FooterIndustryListItem';
import FooterListItem from '@glass/shared/components/Layout/Footer/FooterListItem';
import FooterTitle from '@glass/shared/components/Layout/Footer/FooterTitle';
import BrowseLink from '@glass/shared/components/Link/BrowseLink';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import usePageSeed from '@glass/shared/modules/content/usePageSeed';
import industriesQuery from '@glass/shared/modules/jobTitles/queries/industries.graphql';
import { RANDOM_ORDER_BY } from '@glass/shared/modules/resources/orderBys';

const seeMoreTrackProps = { label: 'footer-see-more' };
function FooterIndustryList({ numItems }) {
  const seed = usePageSeed();
  const resumeWords = useResumeWords();
  const loadingIndustries = useLoadingResources(numItems);

  const isAssignmentReady = useIsAssignmentReady();
  const skip = !isAssignmentReady || !seed;

  const { data, loading } = useQuery(industriesQuery, {
    variables: {
      orderBy: RANDOM_ORDER_BY,
      first: numItems,
      seed,
    },
    skip,
    ssr: false,
  });

  const isLoading = !isAssignmentReady || loading;

  return (
    <>
      <FooterTitle>Find your {resumeWords.singular}</FooterTitle>
      <div>
        {(
          (isLoading && !data?.industries?.length ? loadingIndustries : data?.industries) || []
        ).map((item) => {
          const { slug, width, name } = item || {};
          if (!slug) {
            return null;
          }
          return (
            <FooterIndustryListItem
              key={slug}
              name={isLoading ? <InlineCenterSkeleton inline width={width} /> : name}
              slug={!isLoading ? slug : null}
            />
          );
        })}
        <FooterListItem component={BrowseLink}>
          <BrowseLink trackProps={seeMoreTrackProps}>
            <b>Browse {resumeWords.pluralAbbreviatedCapitalized}</b>
          </BrowseLink>
        </FooterListItem>
      </div>
    </>
  );
}

FooterIndustryList.propTypes = {
  numItems: PropTypes.number,
};

FooterIndustryList.defaultProps = {
  numItems: 4,
};

export default React.memo(FooterIndustryList);
