import serverDate from '@glass/shared/modules/datetime/serverDate';

const generateTimeSeed = (durationMs, date = serverDate) => {
  const timeMs = new Date(date).getTime();
  const raw = timeMs / durationMs;
  const seed = Math.floor(raw);
  const msRemaining = Math.round((seed + 1) * durationMs - timeMs);
  return {
    raw,
    seed: seed.toString(),
    durationMs,
    msRemaining,
    startedAtMs: timeMs + msRemaining - durationMs,
  };
};

export default generateTimeSeed;
