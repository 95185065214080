import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { BASE_PATH_1_PATHNAME } from '@glass/common/modules/pages/paths';
import { BASE_PATH_1_QUERY } from '@glass/common/modules/pages/queryParams';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import Link from '@glass/shared/components/Link/Link';
import useBrowseBasePath from '@glass/shared/components/Link/useBrowseBasePath';

const BrowseLink = React.forwardRef(({ className, basePath1, template, ...otherProps }, ref) => {
  const defaultBrowseBasePath = useBrowseBasePath(basePath1);
  const href = useMemo(
    () => ({
      pathname: BASE_PATH_1_PATHNAME,
      query: removeEmptyKeys({
        [BASE_PATH_1_QUERY]: basePath1 || defaultBrowseBasePath,
        template,
      }),
    }),
    [basePath1, defaultBrowseBasePath, template],
  );
  return <Link {...otherProps} ref={ref} className={className} href={href} />;
});

BrowseLink.propTypes = {
  basePath1: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  seed: PropTypes.string,
  template: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

export default BrowseLink;
