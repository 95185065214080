import { useMemo } from 'react';

import makeFreeBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/makeFreeBuilderBasePath';
import useBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useBuilderBasePath';

const useFreeBuilderBasePath = () => {
  const defaultBuilderBasePath = useBuilderBasePath() || '';
  return useMemo(() => makeFreeBuilderBasePath(defaultBuilderBasePath), [defaultBuilderBasePath]);
};

export default useFreeBuilderBasePath;
