import React, { useCallback, useMemo, useState } from 'react';

import IconButton from '@glass/web/components/base/IconButton';
import { InputProps } from '@glass/web/components/base/Input';
import InputAdornment from '@glass/web/components/base/InputAdornment';
import Zoom from '@glass/web/components/base/Zoom';
import SearchRounded from '@glass/web/components/base/icons/SearchRounded';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import SearchBar from '@glass/shared/components/Search/SearchBar';

const SEARCH_WIDTH = 200;

const useStyles = makeStyles()((theme) => ({
  root: {
    display: FLEX,
    alignItems: CENTER,
    position: 'relative',
    paddingLeft: SEARCH_WIDTH,
  },
  inputRoot: {
    width: SEARCH_WIDTH,
    background: theme.palette.background.default,
    borderRadius: 100,
    padding: theme.spacing(0, 0.5),
  },
  container: {
    display: FLEX,
    alignItems: CENTER,
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
  },
  buttonContainer: {
    marginRight: theme.spacing(1),
  },
}));

const HEADER_ID = 'header';

interface HeaderSearchBarProps {
  sibling: React.JSX.Element;
  enable: boolean;
}

function HeaderSearchBar({ sibling, enable }: HeaderSearchBarProps) {
  const { classes } = useStyles();
  const [isOpen, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const handleOpen = useCallback(() => {
    if (!isOpen) {
      setOpen(true);
    }
    return null;
  }, [isOpen]);

  const handleBlur = useCallback(() => setOpen(false), []);
  const handleChange = useCallback((change: string) => setValue(change), []);

  const textFieldProps: InputProps = useMemo(
    () => ({
      fullWidth: false,
      inputProps: {
        id: `${HEADER_ID}-search`,
        'aria-label': 'Search',
        inputMode: 'search',
      },
      disableUnderline: true,
      startAdornment: (
        <InputAdornment position="start">
          <SearchRounded />
        </InputAdornment>
      ),
    }),
    [],
  );

  return (
    <div className={classes.root}>
      <Zoom appear={false} in={isOpen ? enable : false}>
        <div className={classes.container}>
          {isOpen ? (
            <SearchBar
              key="search-bar"
              autoFocus
              placeHolder="Search Jobs"
              textFieldClassName={classes.inputRoot}
              textFieldProps={textFieldProps}
              value={value}
              onBlur={handleBlur}
              onChange={handleChange}
              onSearchSuccess={handleBlur}
            />
          ) : null}
        </div>
      </Zoom>
      <Zoom appear={false} in={!isOpen && enable}>
        <div className={classes.container}>
          <div className={classes.buttonContainer}>
            <IconButton color="inherit" size="large" onClick={handleOpen}>
              <SearchRounded />
            </IconButton>
          </div>
          {sibling}
        </div>
      </Zoom>
    </div>
  );
}

export default HeaderSearchBar;
