import PropTypes from 'prop-types';
import React from 'react';

import RobotsHead from '@glass/web/modules/head/RobotsHead';
import TitleHead from '@glass/web/modules/head/TitleHead';
import { CENTER } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import ErrorBoundary from '@glass/shared/components/Error/ErrorBoundary';
import ErrorMessageBox from '@glass/shared/components/Error/ErrorMessageBox';
import GlobalDialogContainer from '@glass/shared/components/Global/Dialog/GlobalDialogContainer';
import GlobalLoadingContainer from '@glass/shared/components/Global/Loading/GlobalLoadingContainer';
import GlobalSnackbarContainer from '@glass/shared/components/Global/Snackbar/GlobalSnackbarContainer';
import CanonicalPathHead from '@glass/shared/components/Head/CanonicalPathHead';
import DescriptionHead from '@glass/shared/components/Head/DescriptionHead';
import SiteNameHead from '@glass/shared/components/Head/SiteNameHead';
import ThemeColorHead from '@glass/shared/components/Head/ThemeColorHead';
import TypeHead from '@glass/shared/components/Head/TypeHead';
import ViewportHead from '@glass/shared/components/Head/ViewportHead';
import defaultThemeColor from '@glass/shared/modules/theme/styles/themeColor';

const useStyles = makeStyles()({
  root: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  centerType: {
    justifyContent: 'center',
  },
});

function LayoutPage({
  header,
  footer,
  children,
  pageTitle,
  description,
  canonicalPath,
  brand,
  robots,
  className,
  themeColor,
  type,
}) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <ViewportHead />
      <RobotsHead content={robots} />
      <TitleHead brand={brand} title={pageTitle} />
      <DescriptionHead description={description} />
      <CanonicalPathHead pathname={canonicalPath} />
      <SiteNameHead />
      <TypeHead />
      <ThemeColorHead themeColor={themeColor} />
      {header}
      <main className={cx(classes.main, type === CENTER ? classes.centerType : null)}>
        <ErrorBoundary fallback={ErrorMessageBox} p={2}>
          {children}
        </ErrorBoundary>
      </main>
      {footer}
      <GlobalDialogContainer />
      <GlobalLoadingContainer />
      <GlobalSnackbarContainer />
    </div>
  );
}

LayoutPage.defaultProps = {
  themeColor: defaultThemeColor,
};

LayoutPage.propTypes = {
  brand: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  canonicalPath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  description: PropTypes.string,
  footer: PropTypes.node,
  header: PropTypes.node,
  pageTitle: PropTypes.string,
  robots: PropTypes.string,
  themeColor: PropTypes.string,
  type: PropTypes.oneOf([CENTER]),
};

export default React.memo(LayoutPage);
