/* eslint-disable no-restricted-imports */
import adjectiveAlternativeVariations from '@glass/common/modules/content/staticVariations/adjectiveWords/adjectiveAlternativeVariations';
import adjectiveWordVariations from '@glass/common/modules/content/staticVariations/adjectiveWords/adjectiveWordVariations';
import resumeWordVariations from '@glass/common/modules/content/staticVariations/resumeWords/resumeWordVariations';
import { FREE_RESUME_BUILDER_BASE_PATH } from '@glass/common/modules/pages/paths';

const adjectiveRegex = new RegExp(
  `^(${[...adjectiveWordVariations, ...adjectiveAlternativeVariations].join('|')}|)-`,
);

const regex2 = new RegExp(
  `^(${resumeWordVariations.map((word) => word.replace(/\s/g, '-')).join('|')})-`,
);

const makeFreeBuilderBasePath = (str: string): string => {
  if (!str || str.match?.(/^how-to/)) {
    return FREE_RESUME_BUILDER_BASE_PATH;
  }

  if (str.match?.(adjectiveRegex)) {
    return str.replace(adjectiveRegex, 'free-');
  }

  if (str.match?.(regex2)) {
    return str.replace?.(regex2, 'free-$1-');
  }

  return FREE_RESUME_BUILDER_BASE_PATH;
};

export default makeFreeBuilderBasePath;
