import React from 'react';

import { inverseGoldenRatio } from '@glass/common/modules/goldenRatio/goldenRatio';
import MuiContainer from '@glass/web/components/base/Container';
import { BreakpointTypes, DEFAULT_BP, SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { DEFAULT_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme) => ({
  root: {
    transition: theme.transitions.create('padding'),
    [theme.breakpoints.down(SM)]: {
      padding: theme.spacing(1),
    },
    padding: theme.spacing(2),
    // MUI is setting the padding left & padding right inside this media query and it's overriding our custom padding, so adding this media query makes the css selector deeper to gain precedence over MUI's selector
    // https://github.com/rocket-resume/glass/issues/2926
    [theme.breakpoints.up(SM)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  noTopPadding: {
    paddingTop: '0',
  },
  noBottomPadding: {
    paddingBottom: '0',
  },
  noXPadding: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  fullPage: {
    minHeight: `${inverseGoldenRatio}vh`,
    marginBottom: theme.spacing(DEFAULT_SPACING),
  },
}));

type ContainerTypes = {
  children: React.ReactNode;
  isNoTopPadding?: boolean;
  isNoBottomPadding?: boolean;
  isNoYPadding?: boolean;
  isNoXPadding?: boolean;
  isFullPage?: boolean;
  className?: string;
  maxWidth?: BreakpointTypes | false;
};

function Container(
  {
    children,
    isNoTopPadding,
    isNoBottomPadding,
    isNoYPadding,
    isNoXPadding,
    isFullPage,
    className,
    maxWidth = DEFAULT_BP,
  }: ContainerTypes,
  ref: React.Ref<any>,
) {
  const { classes, cx } = useStyles();
  return (
    <MuiContainer
      ref={ref}
      className={cx(
        className,
        classes.root,
        isNoYPadding || isNoTopPadding ? classes.noTopPadding : null,
        isNoYPadding || isNoBottomPadding ? classes.noBottomPadding : null,
        isNoXPadding ? classes.noXPadding : null,
        isFullPage ? classes.fullPage : null,
      )}
      maxWidth={maxWidth}
    >
      {children}
    </MuiContainer>
  );
}

export default React.forwardRef(Container);
