import PropTypes from 'prop-types';
import React from 'react';

import Typography from '@glass/web/components/base/Typography';
import { MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { EXTRA_SMALL_SPACING } from '@glass/web/modules/theme/spacing';

const useStyles = makeStyles()((theme) => ({
  root: {
    fontWeight: 500,
    textAlign: CENTER,
    marginBottom: theme.spacing(EXTRA_SMALL_SPACING / 2),
    [theme.breakpoints.down(MD)]: {
      marginTop: theme.spacing(EXTRA_SMALL_SPACING),
    },
  },
}));

const FooterTitle = React.memo(({ children }) => {
  const { classes } = useStyles();
  return (
    <Typography align={CENTER} className={classes.root} variant="h4">
      {children}
    </Typography>
  );
});

FooterTitle.propTypes = {
  children: PropTypes.node,
};

export default React.memo(FooterTitle);
