import { useState, useCallback } from 'react';

import useIdleCallback, { IdleCallbackOptions } from '@glass/web/modules/loading/useIdleCallback';

const useIsIdleLoaded = ({ enabled = true, timeout }: IdleCallbackOptions) => {
  const [isIdleLoaded, setIdleLoaded] = useState(false);
  const handleIdleCallback = useCallback(() => setIdleLoaded(true), []);
  useIdleCallback(handleIdleCallback, { enabled, timeout });
  return isIdleLoaded;
};

export default useIsIdleLoaded;
