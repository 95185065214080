import { useMemo } from 'react';

import makePaidBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/makePaidBuilderBasePath';
import useBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useBuilderBasePath';

const usePaidBuilderBasePath = () => {
  const defaultBuilderBasePath = useBuilderBasePath();
  return useMemo(() => makePaidBuilderBasePath(defaultBuilderBasePath), [defaultBuilderBasePath]);
};

export default usePaidBuilderBasePath;
