import { useEffect, useState } from 'react';

import useTracking from '@glass/shared/modules/tracking/useTracking';

const useDisableTracking = (disable = true) => {
  const tracking = useTracking();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (!disabled && disable) {
      tracking.disable();
      setDisabled(true);
    }
  }, [disabled, disable, tracking]);

  return tracking.getDisabled();
};

export default useDisableTracking;
