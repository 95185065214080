import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { BASE_PATH_1_QUERY } from '@glass/common/modules/pages/queryParams';
import ResumeBuilderRouter from '@glass/web/modules/resumes/router/ResumeBuilderRouter';
import useBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/useBuilderBasePath';

import Link from '@glass/shared/components/Link/Link';
import { builderFirstStepParams } from '@glass/shared/modules/resumes/createBuilderFirstStepParams';

const ResumeBuilderLink = React.forwardRef(({ params: paramsProp, ...otherProps }, ref) => {
  // todo: push this into router
  const defaultBuilderBasePath = useBuilderBasePath();

  const href = useMemo(
    () =>
      ResumeBuilderRouter.getResumeBuilderUrl({
        ...paramsProp,
        [BASE_PATH_1_QUERY]: paramsProp?.[BASE_PATH_1_QUERY] || defaultBuilderBasePath,
      }),
    [defaultBuilderBasePath, paramsProp],
  );

  return <Link {...otherProps} ref={ref} href={href} />;
});

ResumeBuilderLink.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

ResumeBuilderLink.defaultProps = {
  params: builderFirstStepParams,
};

ResumeBuilderLink.displayName = 'ResumeBuilderLink';

export default React.memo(ResumeBuilderLink);
