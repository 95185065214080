import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { HEADER_CTA } from '@glass/common/modules/tests/testIds';
import Badge from '@glass/web/components/base/Badge';
import { selectHeaderCtaOpen } from '@glass/web/modules/app';
import useTimeout from '@glass/web/modules/dates/useTimeout';
import { SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import HeaderCtaButton from '@glass/shared/components/Layout/Header/HeaderCtaButton';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';
import useVerbWords from '@glass/shared/modules/content/staticVariations/verbWords/useVerbWords';
import { selectMostRecentResumesCount } from '@glass/shared/modules/resumes/resumesReducer';
import { useTrackingDefault } from '@glass/shared/modules/tracking/useTrackingProp';
import { selectUserContentCount } from '@glass/shared/modules/userContent';

const useBadgeStyles = makeStyles()((theme, { visible }) => ({
  badge: {
    opacity: visible ? 1 : 0,
    backgroundColor: theme.palette.accent[500],
    color: theme.palette.getContrastText(theme.palette.accent[500]),
    [theme.breakpoints.up(SM)]: {
      fontSize: theme.typography.h6.fontSize,
      height: 24,
      minWidth: 24,
      borderRadius: 12,
      top: 2,
    },
  },
}));

function HeaderCta() {
  const contentCount = useSelector(selectUserContentCount);
  const mostRecentResumesCount = useSelector(selectMostRecentResumesCount);
  const badgeCount = mostRecentResumesCount || contentCount;
  const verbWords = useVerbWords();
  const resumeWords = useResumeWords();
  useTrackingDefault('headerBadgeCount', badgeCount);
  const headerCtaOpen = useSelector(selectHeaderCtaOpen);

  const [visible, setVisible] = useState(headerCtaOpen || false);

  const { classes: badgeClasses } = useBadgeStyles({ visible });
  const onReady = useCallback(() => setVisible(true), []);
  useTimeout(onReady, 1200);

  return (
    <Badge
      badgeContent={badgeCount}
      classes={badgeClasses}
      color="secondary"
      invisible={!visible}
      max={99}
      overlap="rectangular"
    >
      <HeaderCtaButton data-test-id={HEADER_CTA}>
        {verbWords.capitalized} {resumeWords.abbreviated}
      </HeaderCtaButton>
    </Badge>
  );
}

export default React.memo(HeaderCta);
