import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const DESCRIPTION = 'description';
const OG_DESCRIPTION = 'og:description';

function Description({ description }) {
  if (!description) {
    return null;
  }

  return (
    <Head>
      <meta key={DESCRIPTION} content={description} name={DESCRIPTION} />
      <meta key={OG_DESCRIPTION} content={description} property={OG_DESCRIPTION} />
    </Head>
  );
}

Description.propTypes = {
  description: PropTypes.string,
};

export default React.memo(Description);
