import { useMemo } from 'react';

export const splitString = (value: unknown) => {
  if (typeof value !== 'string') {
    return /(?:)/;
  }
  return new RegExp(`(${value.trim().split(/\W/).join('|')})`, 'gi');
};

const useHighlightStringRegex = (value: unknown) => useMemo(() => splitString(value), [value]);

export default useHighlightStringRegex;
