import { GREY_200 } from '@glass/web/modules/theme/colors';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import alternatingGrayBackground from '@glass/shared/modules/theme/styles/alternatingGrayBackground';

const useAlternatingBackgroundStyles = makeStyles()((theme, { bgColor = GREY_200 } = {}) => {
  const border = `1px solid ${theme.palette.divider}`;
  return {
    section: {
      ...alternatingGrayBackground({
        backgroundColor: bgColor,
      }),
      borderBottom: border,
    },
    lastSection: {
      borderBottom: 0,
    },
  };
});

export default useAlternatingBackgroundStyles;
