import { useRouter } from 'next/router';
import React from 'react';

import { SUPPORT_PATHNAME } from '@glass/common/modules/pages/paths';
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SUPPORT_PHONE_FORMATTED,
} from '@glass/common/modules/support/rock';

import FooterListItem from '@glass/shared/components/Layout/Footer/FooterListItem';
import FooterTitle from '@glass/shared/components/Layout/Footer/FooterTitle';
import LegalLink from '@glass/shared/components/Link/LegalLink';
import Link from '@glass/shared/components/Link/Link';

function FooterContactList() {
  const { pathname } = useRouter();
  return (
    <>
      <FooterTitle>Contact</FooterTitle>
      <div>
        {pathname !== SUPPORT_PATHNAME ? (
          <FooterListItem>
            <Link href={SUPPORT_PATHNAME}>Customer Support</Link>
          </FooterListItem>
        ) : null}
        <FooterListItem>
          <Link href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Link>
        </FooterListItem>
        <FooterListItem>
          <Link href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE_FORMATTED}</Link>
        </FooterListItem>
        <FooterListItem>
          <LegalLink>Terms & Privacy</LegalLink>
        </FooterListItem>
        {pathname !== SUPPORT_PATHNAME ? (
          <FooterListItem>
            <Link href={SUPPORT_PATHNAME}>
              <b>Contact us</b>
            </Link>
          </FooterListItem>
        ) : null}
      </div>
    </>
  );
}

FooterContactList.displayName = 'FooterContactList';

export default FooterContactList;
