import {
  PLURAL_CAPITALIZED_KEY,
  SINGULAR_KEY,
} from '@glass/common/modules/content/staticVariations/createWordVariations';
import {
  // TODO Taxonomy: Remove these two for taxonomy second deployment
  CURRICULUM_VITAE_PLURAL_CAPITALIZED,
  CURRICULUM_VITAE_RESUME_WORD, //
  CV_CAPITALIZED_PLURAL,
  CV_RESUME_WORD,
  DEFAULT_RESUME_WORDS,
} from '@glass/common/modules/content/staticVariations/resumeWords/resumeWordVariations';
import {
  CURRICULUM_VITAE_BASE_PATH, // TODO Taxonomy: Remove this for taxonomy second deployment
  CV_BASE_PATH,
  RESUME_BASE_PATH,
} from '@glass/common/modules/pages/paths';

// only add bare minimum keys here
const browsePathSettings = {
  [CV_BASE_PATH]: {
    resumeWords: {
      [SINGULAR_KEY]: CV_RESUME_WORD,
      [PLURAL_CAPITALIZED_KEY]: CV_CAPITALIZED_PLURAL,
    },
  },
  // TODO Taxonomy: Remove this for taxonomy second deployment
  [CURRICULUM_VITAE_BASE_PATH]: {
    resumeWords: {
      [SINGULAR_KEY]: CURRICULUM_VITAE_RESUME_WORD,
      [PLURAL_CAPITALIZED_KEY]: CURRICULUM_VITAE_PLURAL_CAPITALIZED,
    },
  },
  //
  [RESUME_BASE_PATH]: {
    resumeWords: DEFAULT_RESUME_WORDS,
  },
};

export default browsePathSettings;
