import React from 'react';

import goldenRatio from '@glass/common/modules/goldenRatio/goldenRatio';
import CaptionTypography, {
  EXTRA_SMALL_FONT_SIZE,
} from '@glass/web/components/Typography/CaptionTypography';
import SecurityRounded from '@glass/web/components/base/icons/SecurityRounded';
import { CENTER } from '@glass/web/modules/theme/constants';
import { FLEX } from '@glass/web/modules/theme/flexConstants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()((theme) => ({
  root: {
    alignItems: CENTER,
    display: FLEX,
  },
  badge: {
    display: FLEX,
    marginRight: theme.spacing(1 / goldenRatio),
  },
  content: {
    display: FLEX,
    flexDirection: 'column',
  },
}));

type SecureServerBadgeProps = {
  className?: string;
};

function SecureServerBadge({ className }: SecureServerBadgeProps) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(className, classes.root)}>
      <div className={classes.badge}>
        <SecurityRounded />
      </div>
      <div>
        <div className={classes.content}>
          <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>Secure Server</CaptionTypography>
          <CaptionTypography fontSize={EXTRA_SMALL_FONT_SIZE}>SSL Encryption</CaptionTypography>
        </div>
      </div>
    </div>
  );
}

export default React.memo(SecureServerBadge);
