import { BASE_PATH_2_QUERY } from '@glass/common/modules/pages/queryParams';
import { metaStepData } from '@glass/web/modules/resumes/router/stepData';

import getBuilderSectionStepParams from '@glass/shared/components/Link/ResumeBuilderLink/getBuilderSectionStepParams';

const createBuilderFirstStepParams = (params = {}) => {
  return getBuilderSectionStepParams({
    ...params,
    [BASE_PATH_2_QUERY]: params[BASE_PATH_2_QUERY] || metaStepData.firstSection,
  });
};

export const builderFirstStepParams = createBuilderFirstStepParams();

export default createBuilderFirstStepParams;
