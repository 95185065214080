import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

const OG_TYPE = 'og:type';
const TWITTER_CARD = 'twitter:card';

function TypeHead({ type, card }) {
  return (
    <Head>
      <meta key={OG_TYPE} content={type} property={OG_TYPE} />
      <meta key={TWITTER_CARD} content={card} property={TWITTER_CARD} />
    </Head>
  );
}

TypeHead.propTypes = {
  card: PropTypes.string,
  type: PropTypes.string,
};

TypeHead.defaultProps = {
  type: 'website',
  card: 'summary',
};

export default React.memo(TypeHead);
