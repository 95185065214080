import { alpha, darken } from '@mui/material/styles';

import { Theme } from '@glass/web/components/base/Theme';
import generateBackdropFilter from '@glass/web/modules/theme/styles/generateBackdropFilter';

const backdrop = (
  theme: Theme,
  {
    base = theme.palette.common.white,
    tintColor = '',
    opacity = 0.1,
    darkness = 0.5,
    filters = {
      blur: '8px',
      contrast: '95%',
      brightness: '75%',
    },
  } = {},
) => ({
  backgroundColor: tintColor ? alpha(darken(tintColor, darkness), opacity) : alpha(base, opacity),
  backdropFilter: generateBackdropFilter(filters),
});

export default backdrop;
