import Script from 'next/script';
import React from 'react';

import useIsPriorityLoadedIdle from '@glass/web/modules/loading/useIsPriorityLoadedIdle';

// this allows queuing events before the script loads
const FB_QUEUE_SCRIPT = `!function(f,b,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[]}(window,document);`;

function FacebookPixelScriptHead() {
  const isPriorityLoadedIdle = useIsPriorityLoadedIdle({
    enabled: !!process.env.NEXT_PUBLIC_FACEBOOK_ID,
  });

  if (!process.env.NEXT_PUBLIC_FACEBOOK_ID) {
    return null;
  }

  return (
    <>
      {/* this should be inline but isn't working locally */}
      <Script id="fb-inline" strategy="beforeInteractive">
        {FB_QUEUE_SCRIPT}
      </Script>
      {isPriorityLoadedIdle ? (
        <Script
          id="fb"
          defer
          // @ts-ignore
          fetchpriority="low"
          src="https://connect.facebook.net/en_US/fbevents.js"
          strategy="lazyOnload"
        />
      ) : null}
    </>
  );
}

export default React.memo(FacebookPixelScriptHead);
