import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { currentOrigin } from '@glass/env/modules/origins/constants';

const CANONICAL = 'canonical';
// const ALTERNATE = 'alternate';
const OG_URL = 'og:url';

function CanonicalPathHead({ pathname }) {
  const url = useMemo(() => `${currentOrigin}${pathname?.replace?.(/\/$/, '') || ''}`, [pathname]);

  if (!pathname) {
    return null;
  }

  return (
    <Head>
      <link key={CANONICAL} href={url} rel={CANONICAL} />
      <meta key={OG_URL} content={url} property={OG_URL} />
    </Head>
  );
}

CanonicalPathHead.propTypes = {
  pathname: PropTypes.string,
};

export default React.memo(CanonicalPathHead);
