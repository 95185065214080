import React from 'react';

import useCenterLargeStyles from '@glass/web/components/Typography/useCenterLargeStyles';
import MuiTypography, { TypographyProps } from '@glass/web/components/base/Typography';
import { CENTER_LARGE } from '@glass/web/modules/theme/constants';

type InternalTypographyProps = {
  align?: typeof CENTER_LARGE | TypographyProps['align'];
} & TypographyProps;

function Typography({ className, align: alignProp, ...otherProps }: InternalTypographyProps) {
  const { classes, cx } = useCenterLargeStyles();

  return (
    <MuiTypography
      align={alignProp === CENTER_LARGE ? undefined : alignProp}
      className={cx(className, alignProp === CENTER_LARGE ? classes.centerLarge : null)}
      {...otherProps} // eslint-disable-line react/jsx-props-no-spreading
    />
  );
}

export default React.memo(Typography);
