import PropTypes from 'prop-types';
import React from 'react';

import { HOME_PATHNAME } from '@glass/common/modules/pages/paths';
import Logo, { NATURAL_HEIGHT, NATURAL_WIDTH } from '@glass/web/components/App/Logo';
import { SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import Link from '@glass/shared/components/Link/Link';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const useStyles = makeStyles()((theme) => ({
  logo: {
    width: 76,
    height: (76 * NATURAL_HEIGHT) / NATURAL_WIDTH,
    [theme.breakpoints.up(SM)]: {
      width: 95,
      height: (95 * NATURAL_HEIGHT) / NATURAL_WIDTH,
    },
  },
}));

const BaseLogoLink = React.forwardRef(({ className, onClick }, ref) => {
  const { classes } = useStyles();
  return (
    <Link ref={ref} className={className} href={HOME_PATHNAME} onClick={onClick}>
      <Logo className={classes.logo} />
    </Link>
  );
});

BaseLogoLink.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default withTrackingContext(BaseLogoLink, 'logo');
