import { useMemo } from 'react';

import createSkeletonWidth from '@glass/common/modules/skeleton/createSkeletonWidth';

type LoadingResourceOptionsType = {
  key?: string;
};

const useLoadingResources = <T>(
  numLoading: number,
  { key = 'slug' }: LoadingResourceOptionsType = {},
) => {
  return useMemo<T[]>(
    () =>
      Array.from(
        { length: numLoading },
        (_, idx) =>
          ({
            [key]: idx.toString(),
            width: createSkeletonWidth(idx),
          } as T),
      ),
    [numLoading, key],
  );
};

export default useLoadingResources;
