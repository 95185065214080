import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsAppLoading } from '@glass/web/modules/app';
import useIsDocumentReadyPriorityLoadedIdle from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdle';

import DynamicGlobalLoading from '@glass/shared/components/Global/Loading/DynamicGlobalLoading';

function GlobalLoadingContainer() {
  const isDocumentReadyPriorityLoadedIdle = useIsDocumentReadyPriorityLoadedIdle();
  const isAppLoading = useSelector(selectIsAppLoading);
  if (!isAppLoading && !isDocumentReadyPriorityLoadedIdle) {
    return null;
  }
  return <DynamicGlobalLoading loading={!!isAppLoading} />;
}

export default React.memo(GlobalLoadingContainer);
