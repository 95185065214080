import React, { useCallback } from 'react';

import Spinner from '@glass/web/components/Loading/Spinner';
import FormControl from '@glass/web/components/base/FormControl';
import Input, { InputProps } from '@glass/web/components/base/Input';
import InputAdornment from '@glass/web/components/base/InputAdornment';
import InputLabel from '@glass/web/components/base/InputLabel';
import makeStyles from '@glass/web/modules/theme/makeStyles';

interface InputFieldProps {
  id?: string;
  className?: string;
  disabled?: boolean;
  disableUnderline?: boolean;
  endAdornment?: React.ReactNode;
  isLoading?: boolean;
  autoFocus?: boolean;
  getInputProps?: () => React.InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  anchorEl?: () => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  textFieldProps: InputProps;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  value?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  label: {
    display: 'block',
  },
  input: {
    width: 'auto',
    flexGrow: 1,
    '&.Mui-selected': {
      outline: 'none',
    },
  },
  spinnerContainer: {
    position: 'relative',
    marginRight: theme.spacing(1.5),
    marginLeft: 0,
  },
}));

const InputField = React.forwardRef(
  (
    {
      id,
      disabled,
      autoFocus,
      startAdornment,
      placeholder,
      disableUnderline,
      endAdornment,
      className,
      label,
      isLoading,
      onChange,
      onFocus,
      onBlur,
      value,
      textFieldProps,
      anchorEl,
    }: InputFieldProps,
    ref,
  ) => {
    const { classes, cx } = useStyles();

    const labelString = label || textFieldProps?.inputProps?.label;

    const labelComponent = labelString ? (
      <InputLabel className={classes.label} htmlFor={id}>
        {labelString}
      </InputLabel>
    ) : null;

    const loadingEndAdornment = (
      <InputAdornment className={classes.spinnerContainer} position="end">
        <Spinner loading size={16} />
      </InputAdornment>
    );

    const inputEndAdornment = endAdornment || (isLoading ? loadingEndAdornment : null);

    const { ref: inputRef, ...textFieldPropsRest } = textFieldProps;
    const handleBlur = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur?.(e);
        textFieldProps?.onBlur?.(e);
      },
      [onBlur, textFieldProps],
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLInputElement>) => {
        onFocus?.(e);
        textFieldProps?.onFocus?.(e);
      },
      [onFocus, textFieldProps],
    );

    return (
      <FormControl ref={anchorEl} fullWidth className={classes.root} variant="standard">
        {labelComponent}
        <Input
          id={id}
          disabled={disabled}
          autoFocus={autoFocus}
          value={value}
          margin="dense"
          startAdornment={startAdornment}
          placeholder={placeholder}
          endAdornment={inputEndAdornment}
          ref={ref}
          disableUnderline={disableUnderline}
          className={cx(classes.input, className)}
          onChange={onChange}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...textFieldPropsRest}
          onFocus={handleFocus}
          onBlur={handleBlur}
          inputProps={{ ref: inputRef }}
        />
      </FormControl>
    );
  },
);

InputField.displayName = 'AutocompleteInput';

export default InputField;
