import { Theme } from '@glass/web/components/base/Theme';
import backdrop from '@glass/web/modules/theme/styles/backdrop';

const darkBackdrop = (
  theme: Theme,
  { base = theme.palette.common.black, tintColor = '', opacity = 0.5, ...otherOptions } = {},
) =>
  backdrop(theme, {
    tintColor,
    base,
    opacity,
    ...otherOptions,
  });

export default darkBackdrop;
