import Head from 'next/head';
import React, { useMemo } from 'react';

import getBrandTitle, { BrandTitleProps } from '@glass/common/modules/strings/getBrandTitle';

const OG_TITLE = 'og:title';
const TWITTER_TITLE = 'twitter:title';

/**
 *
 * @param {string} title - page title
 * @param {string} brand - brand name
 */
function TitleHead({ title, brand = process.env.NEXT_PUBLIC_BRAND_TITLE }: BrandTitleProps) {
  const pageTitle = useMemo(() => getBrandTitle({ title, brand }), [brand, title]);

  return (
    <Head>
      <title key="title">{pageTitle}</title>
      <meta property={OG_TITLE} content={pageTitle} key={OG_TITLE} />
      <meta key={TWITTER_TITLE} content={pageTitle} property={TWITTER_TITLE} />
    </Head>
  );
}

export default React.memo(TitleHead);
