import { useMemo } from 'react';

import {
  BROWSE_BASE_PATHS,
  DEPRECATED_BASE_PATHS_MAPPING,
} from '@glass/common/modules/pages/paths';
import createSlug from '@glass/common/modules/url/createSlug';

import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';

const useBrowseBasePath = (proposedBasePath) => {
  const { plural: resumeWordPlural } = useResumeWords() || {};
  return useMemo(() => {
    if (proposedBasePath && BROWSE_BASE_PATHS.includes(proposedBasePath)) {
      return proposedBasePath;
    }
    const resumeWordSlug = createSlug(resumeWordPlural);
    return DEPRECATED_BASE_PATHS_MAPPING[resumeWordSlug] || resumeWordSlug;
  }, [proposedBasePath, resumeWordPlural]);
};

export default useBrowseBasePath;
