type Params = { [key: string]: string };

const generateBackdropFilter = (filters: Params) =>
  Object.keys(filters).reduce((backdropFilter, key) => {
    const value = filters[key];
    if (typeof value === 'string') {
      return `${backdropFilter && `${backdropFilter} `}${key}(${value})`;
    }
    return backdropFilter;
  }, '');

export default generateBackdropFilter;
