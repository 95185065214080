import seedrandom from 'seedrandom';

import adjectiveWordVariations from '@glass/common/modules/content/staticVariations/adjectiveWords/adjectiveWordVariations';
import { RESUME_BASE_PATH } from '@glass/common/modules/pages/paths';
import {
  BASE_PATH_1_QUERY,
  BASE_PATH_2_QUERY,
  BASE_PATH_3_QUERY,
} from '@glass/common/modules/pages/queryParams';
import randomFromArraySeed from '@glass/common/modules/random/randomFromArraySeed';
import getArrayValueFromFloat from '@glass/common/modules/utils/getArrayValueFromFloat';
import removeEmptyKeys from '@glass/common/modules/utils/removeEmptyKeys';

import contentWordVariations from '@glass/shared/modules/content/staticVariations/contentWords/contentWordVariations';
import builderWordVariations from '@glass/shared/modules/content/staticVariations/verbWords/builderWordVariations';

const compileJobTitleSlug = ({
  jobTitle,
  content,
  builder,
  adjective,
}: {
  jobTitle: string;
  content: string;
  builder: string;
  adjective: string;
}) => `${jobTitle}-${adjective}-resume-${builder}-${content}` as const;

const MISC_SLUG = 'misc';

type Industry = { slug: string };

// if there is more than 1 industry then select a random one as the default that's not misc
const getPreferredIndustries = (industries: Array<Industry>) =>
  industries?.length > 1
    ? // We add a map and sort here to ensure that the order of the industries is consistent
      // and all input industries only care about the slug, so __typename and other properties are removed
      [...industries]
        .map((e) => ({ slug: e.slug }))
        .sort((a, z) => a.slug.localeCompare(z.slug))
        .filter(({ slug }) => slug !== MISC_SLUG)
    : industries;

export type GetJobTitleLinkParamsProps = {
  [BASE_PATH_1_QUERY]?: string;
  [BASE_PATH_2_QUERY]?: string;
  [BASE_PATH_3_QUERY]: string;
  industries: Array<Industry>;
  template?: string;
  seed?: string;
};

// TODO: move this to the server
const getJobTitleLinkParams = ({
  [BASE_PATH_1_QUERY]: basePath1 = RESUME_BASE_PATH,
  [BASE_PATH_2_QUERY]: industrySlugProp,
  [BASE_PATH_3_QUERY]: jobTitleSlug,
  industries,
  template,
  seed,
}: GetJobTitleLinkParamsProps) => {
  let validIndustrySlug = industrySlugProp || null;

  // if we have industries to validate against, otherwise it's presumed valid if it's being passed in
  if (industrySlugProp && industries.length > 0) {
    validIndustrySlug =
      industries.find((industry) => industry.slug === industrySlugProp)?.slug || null;
    // we're going to default to the preferred industry if its misc and we have the array of industries
    if (validIndustrySlug === MISC_SLUG) {
      validIndustrySlug = null;
    }
  }

  const industrySlug =
    validIndustrySlug ||
    randomFromArraySeed<Industry>(getPreferredIndustries(industries), jobTitleSlug)?.slug ||
    MISC_SLUG;

  const rng = seedrandom(
    `${basePath1 === RESUME_BASE_PATH ? '' : basePath1}${industrySlug}${jobTitleSlug}`,
  );
  const content = getArrayValueFromFloat(contentWordVariations, rng()) || '';
  const builder = getArrayValueFromFloat(builderWordVariations, rng()) || '';
  const adjective = getArrayValueFromFloat(adjectiveWordVariations, rng()) || '';

  // TODO Taxonomy: For next taxonomy deployment, we will do 50/50 split for word variations
  // const isSlug = getArrayValueFromFloat([true, false], rng());
  // const basePath3 = isSlug
  //   ? compileJobTitleSlug({
  //       jobTitle: jobTitleSlug,
  //       content,
  //       builder,
  //       adjective,
  //     })
  //   : jobTitleSlug;
  const basePath3 = compileJobTitleSlug({
    jobTitle: jobTitleSlug,
    content,
    builder,
    adjective,
  });

  return removeEmptyKeys({
    template,
    seed,
    [BASE_PATH_1_QUERY]: basePath1,
    [BASE_PATH_2_QUERY]: industrySlug,
    [BASE_PATH_3_QUERY]: basePath3,
  });
};

export default getJobTitleLinkParams;
