import React from 'react';

import goldenRatio from '@glass/common/modules/goldenRatio/goldenRatio';
import useCenterLargeStyles from '@glass/web/components/Typography/useCenterLargeStyles';
import { MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER, CENTER_LARGE } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';
import { SMALL_SPACING } from '@glass/web/modules/theme/spacing';

type SectionSpacerProps = {
  align?: string;
  children: React.ReactNode;
  className?: string;
  edge?: typeof BOTTOM_EDGE | typeof TOP_EDGE | typeof BOTH_EDGE | null;
  spacing?: number;
};

const useStyles = makeStyles<{ spacing: number }>()((theme, { spacing: spacingProp }) => {
  const spacing = theme.spacing(spacingProp);
  const smallSpacing = theme.spacing(spacingProp / goldenRatio);
  return {
    root: {
      marginTop: spacing,
      marginBottom: spacing,
      [theme.breakpoints.down(MD)]: {
        marginTop: smallSpacing,
        marginBottom: smallSpacing,
      },
    },
    noMarginBottom: {
      marginBottom: '0px!important',
    },
    noMarginTop: {
      marginTop: '0px!important',
    },
  };
});

export const BOTTOM_EDGE = 'bottom' as const;
export const TOP_EDGE = 'top' as const;
export const BOTH_EDGE = 'both' as const;

function SectionSpacer({
  align,
  children,
  edge,
  spacing = SMALL_SPACING,
  className,
}: SectionSpacerProps) {
  const { classes, cx } = useStyles({ spacing });
  const { classes: centerLargeClasses } = useCenterLargeStyles();
  return (
    <div
      className={cx(
        classes.root,
        edge === BOTTOM_EDGE ? classes.noMarginBottom : null,
        edge === TOP_EDGE ? classes.noMarginTop : null,
        edge === BOTH_EDGE ? classes.noMarginTop : null,
        edge === BOTH_EDGE ? classes.noMarginBottom : null,
        align === CENTER ? centerLargeClasses.center : null,
        align === CENTER_LARGE ? centerLargeClasses.centerLarge : null,
        className,
      )}
    >
      {children}
    </div>
  );
}

export default SectionSpacer;
