import Head from 'next/head';
import PropTypes from 'prop-types';
import React from 'react';

import { SUPPORT_TWITTER } from '@glass/common/modules/support/rock';

const OG_SITE_NAME = 'og:site_name';
const TWITTER_SITE_NAME = 'twitter:site';

function SiteNameHead({ siteName, twitter }) {
  if (!siteName && !twitter) {
    return null;
  }
  return (
    <Head>
      {siteName ? <meta key={OG_SITE_NAME} content={siteName} property={OG_SITE_NAME} /> : null}
      {twitter ? (
        <meta key={TWITTER_SITE_NAME} content={twitter} property={TWITTER_SITE_NAME} />
      ) : null}
    </Head>
  );
}

SiteNameHead.propTypes = {
  siteName: PropTypes.string,
  twitter: PropTypes.string,
};

SiteNameHead.defaultProps = {
  siteName: process.env.NEXT_PUBLIC_BRAND_TITLE,
  twitter: SUPPORT_TWITTER,
};

export default React.memo(SiteNameHead);
