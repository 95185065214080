import { BASE_PATH_2_QUERY, BASE_PATH_3_QUERY } from '@glass/common/modules/pages/queryParams';
import { Section } from '@glass/web/modules/resumes/router/sections';
import stepData from '@glass/web/modules/resumes/router/stepData';
import { ROOT_STEP, Step } from '@glass/web/modules/resumes/router/steps';

type Props = {
  [BASE_PATH_2_QUERY]: Section;
  skip?: Array<Step>;
  priority?: Array<Step>;
};

const getBuilderSectionStepParams = ({
  [BASE_PATH_2_QUERY]: section,
  skip = [],
  priority = [],
  ...params
}: Props) => {
  if (!section || !Array.isArray(stepData[section]?.steps)) {
    throw new Error(`no step data for ${section}`);
  }
  const { steps } = stepData[section];
  let firstSectionStep = priority.find((pri) => steps.includes(pri));
  if (!firstSectionStep) {
    firstSectionStep = steps?.find((step) => !skip.includes(step));
  }

  return {
    ...params,
    [BASE_PATH_2_QUERY]: section,
    [BASE_PATH_3_QUERY]: firstSectionStep === ROOT_STEP ? undefined : firstSectionStep,
  };
};

export default getBuilderSectionStepParams;
