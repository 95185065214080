import generateContentTimeSeed from '@glass/shared/modules/content/generateContentTimeSeed';
import randomFromSeed from '@glass/shared/modules/random/randomFromSeed';

export const DEFAULT_SEED_ITERATIONS = 40;

export const isValidSeed = (seed, max = DEFAULT_SEED_ITERATIONS) => {
  const seedNum = parseInt(seed, 10);
  return seedNum >= 0 && seedNum <= max;
};

const getIdMax = ({ seedOverride, fallbackId, iSlug, jSlug, max = DEFAULT_SEED_ITERATIONS }) => {
  if (jSlug) {
    if (iSlug) {
      return [`${jSlug}${iSlug}`, DEFAULT_SEED_ITERATIONS, seedOverride];
    }
    if (__DEV__) {
      console.warn(`no iSlug provided with jSlug ${jSlug}`);
    }
  }
  if (iSlug) {
    return [iSlug, DEFAULT_SEED_ITERATIONS, seedOverride];
  }

  return [fallbackId, max, seedOverride];
};

const generateContentSeed = (id, max = DEFAULT_SEED_ITERATIONS, seedOverride) => {
  if (typeof seedOverride !== 'undefined' && isValidSeed(seedOverride, max)) {
    return seedOverride;
  }
  if (!id) {
    return undefined;
  }
  const contentTimeSeed = generateContentTimeSeed(id).seed;
  return Math.floor(max * randomFromSeed(contentTimeSeed)).toString();
};

export const createContentSeed = (props) => generateContentSeed(...getIdMax(props));

export const createBatchedContentSeeds = (props, length) => {
  const [id, max, seedOverride] = getIdMax(props);
  const baseContentNum = parseInt(seedOverride || generateContentSeed(id, max), 10);
  const batchedSeeds = [];
  for (let i = 0; i < length; i += 1) {
    const seedNum = (i + baseContentNum) % (max + 1);
    batchedSeeds.push(seedNum.toString());
  }
  return batchedSeeds;
};
