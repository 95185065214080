import PropTypes from 'prop-types';
import React from 'react';

import FooterListItem from '@glass/shared/components/Layout/Footer/FooterListItem';
import IndustryLink from '@glass/shared/components/Link/IndustryLink';
import useResumeWords from '@glass/shared/modules/content/staticVariations/resumeWords/useResumeWords';

function FooterIndustryListItem({ name, slug }) {
  const resumeWords = useResumeWords();
  const children = (
    <span>
      {name} {resumeWords.pluralAbbreviatedCapitalized}
    </span>
  );
  return (
    <FooterListItem>
      {slug ? <IndustryLink industrySlug={slug}>{children}</IndustryLink> : children}
    </FooterListItem>
  );
}

FooterIndustryListItem.propTypes = {
  name: PropTypes.node,
  slug: PropTypes.string,
};

export default React.memo(FooterIndustryListItem);
