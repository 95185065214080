import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsGlobalDialogOpen } from '@glass/web/modules/globalDialog';
import useIsDocumentReadyPriorityLoadedIdle from '@glass/web/modules/loading/useIsDocumentReadyPriorityLoadedIdle';

import DynamicGlobalDialog from '@glass/shared/components/Global/Dialog/DynamicGlobalDialog';

function GlobalDialogContainer() {
  const isDocumentReadyPriorityLoadedIdle = useIsDocumentReadyPriorityLoadedIdle();
  const isGlobalDialogOpen = useSelector(selectIsGlobalDialogOpen);
  if (!isGlobalDialogOpen && !isDocumentReadyPriorityLoadedIdle) {
    return null;
  }
  return <DynamicGlobalDialog open={!!isGlobalDialogOpen} />;
}

export default React.memo(GlobalDialogContainer);
