import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';

const useClientReadyRouter = () => {
  const { isReady, ...router } = useRouter();
  const [isClientReady, setIsClientReady] = useState(false);

  useEffect(() => {
    setIsClientReady(true);
  }, []);

  return {
    isReady,
    isClientReady,
    ...router,
  };
};

export default useClientReadyRouter;
