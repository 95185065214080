import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import SectionButtonCta from '@glass/shared/components/Section/SectionButtonCta';
import useExtendText from '@glass/shared/modules/content/useExtendText';
import builderCtaText from '@glass/shared/modules/resumes/builderCtaText';

const SectionButtonContinueCta = React.forwardRef(
  ({ params: paramsProp, onClick, disabled, children, ctaQueryParams, component, href }, ref) => {
    const params = useMemo(
      () => ({ ...paramsProp, ...ctaQueryParams }),
      [ctaQueryParams, paramsProp],
    );

    const extendedChildren = useExtendText(children);

    return (
      <SectionButtonCta
        ref={ref}
        disableElevation
        component={component}
        disabled={disabled}
        href={href}
        params={params}
        onClick={onClick}
      >
        {extendedChildren}
      </SectionButtonCta>
    );
  },
);

SectionButtonContinueCta.propTypes = {
  children: PropTypes.node,
  component: PropTypes.elementType,
  // eslint-disable-next-line react/forbid-prop-types
  ctaQueryParams: PropTypes.object,
  disabled: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  params: PropTypes.object,
};

SectionButtonContinueCta.defaultProps = {
  children: builderCtaText,
  ctaQueryParams: null,
  component: ResumeBuilderLink,
};

export default React.memo(SectionButtonContinueCta);
