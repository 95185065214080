import Script from 'next/script';
import React from 'react';

import { WINDOW_REDUX_KEY } from '@glass/web/components/App/constants';
import useIsPriorityLoadedIdle from '@glass/web/modules/loading/useIsPriorityLoadedIdle';

import tracking from '@glass/shared/modules/tracking';
import { zendeskLoadedAction } from '@glass/shared/modules/zendesk/zendeskReducer';

const settings = {
  webWidget: {
    contactForm: {
      attachments: false,
    },
    chat: {
      connectOnPageLoad: false,
    },
    zIndex: 1199,
  },
};

const SRC = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.NEXT_PUBLIC_ZENDESK_KEY}`;

// todo: make this inline?
if (typeof window !== 'undefined') {
  window.zESettings = settings;
}

// todo: queue zendesk events and load immediately when they're called and then fire them when the script is loaded
const handleLoad = () => {
  window[WINDOW_REDUX_KEY].store.dispatch(zendeskLoadedAction());
  tracking.initZendesk();
};

function ZendeskWidgetScriptHead() {
  const isPriorityLoadedIdle = useIsPriorityLoadedIdle({
    enabled: !!process.env.NEXT_PUBLIC_ZENDESK_KEY,
  });

  if (!process.env.NEXT_PUBLIC_ZENDESK_KEY || !isPriorityLoadedIdle) {
    return null;
  }

  return (
    <Script
      defer
      fetchpriority="low"
      id="ze-snippet"
      src={SRC}
      strategy="lazyOnload"
      onLoad={handleLoad}
    />
  );
}

export default React.memo(ZendeskWidgetScriptHead);
