import Script from 'next/script';
import React from 'react';

import parseCsvString from '@glass/common/modules/strings/parseCsvString';

const propertyIds = parseCsvString(process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID || '');
const SRC = `https://www.googletagmanager.com/gtag/js?id=${propertyIds?.[0]}`;

function GoogleAnalyticsScriptHead() {
  if (!process.env.NEXT_PUBLIC_GOOGLE_TRACKING_ID) {
    return null;
  }

  return <Script id="ga" defer src={SRC} type="text/javascript" />;
}

export default React.memo(GoogleAnalyticsScriptHead);
