import { MD } from '@glass/web/modules/theme/breakpointKeys';
import { CENTER, LEFT } from '@glass/web/modules/theme/constants';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useCenterLargeStyles = makeStyles()((theme) => ({
  center: {
    textAlign: CENTER,
  },
  centerLarge: {
    textAlign: CENTER,
    [theme.breakpoints.down(MD)]: {
      textAlign: LEFT,
    },
  },
}));

export default useCenterLargeStyles;
