import React from 'react';

import ErrorBoundary from '@glass/shared/components/Error/ErrorBoundary';

const withErrorBoundary = (Component, ErrorComponent, staticProps = {}) =>
  React.forwardRef((props, ref) => (
    <ErrorBoundary fallback={ErrorComponent}>
      <Component ref={ref} {...staticProps} {...props} />
    </ErrorBoundary>
  ));

export default withErrorBoundary;
