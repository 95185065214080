import { useRouter } from 'next/router';

import { PRESENTATION_QUERY } from '@glass/common/modules/pages/queryParams';

import useDisableTracking from '@glass/shared/components/hooks/useDisableTracking';

const usePresentation = () => {
  const {
    query: { [PRESENTATION_QUERY]: presentation },
  } = useRouter();
  const isPresentation = !!presentation;
  useDisableTracking(isPresentation);
  return isPresentation;
};

export default usePresentation;
