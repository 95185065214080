import { MAX_TITLE_LENGTH_WITHOUT_BRAND } from '@glass/common/modules/content/titleLengths';
import filterJoin from '@glass/common/modules/strings/filterJoin';

export const TITLE_DELIMITER = ' | ';

export type BrandTitleProps = {
  brand?: unknown;
  title?: unknown;
};

/**
 * Returns the brand and title joined by a delimiter.
 * @param brand The brand of the product.
 * @param title The title of the product.
 * @returns The brand and title joined by a delimiter.
 */
const getBrandTitle = ({ brand, title }: BrandTitleProps) => {
  if (typeof title === 'string' && title.length > MAX_TITLE_LENGTH_WITHOUT_BRAND) {
    return title;
  }
  const arr = brand === title ? [title] : [title, brand];
  return filterJoin(arr, TITLE_DELIMITER);
};

export default getBrandTitle;
