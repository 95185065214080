import Head from 'next/head';
import Script from 'next/script';
import React from 'react';

const SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM_ID}');`;

// this can run lazily as the datalayer and gtag are initialized in tracking.js
function GoogleTagManagerScriptHead() {
  return process.env.NEXT_PUBLIC_GTM_ID ? (
    <>
      <Head>
        <link key="gtms-preconnect" rel="preconnect" href="https://www.googletagmanager.com" />
      </Head>
      <Script id="gtms">{SCRIPT}</Script>
    </>
  ) : null;
}
export default React.memo(GoogleTagManagerScriptHead);
