import { useCallback, useEffect, useRef } from 'react';

import setupRequestIdleCallbackShim from '@glass/web/modules/loading/setupRequestIdleCallbackShim';

setupRequestIdleCallbackShim();

// Define types for the callback and options
export type IdleCallbackOptions = {
  timeout?: number;
  enabled?: boolean;
};

const useIdleCallback = (
  callback: () => void,
  { enabled = true, timeout }: IdleCallbackOptions = {},
): void => {
  const callbackRef = useRef(callback);
  const didRun = useRef(false);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleIdleCallback = useCallback((deadline: IdleDeadline): void => {
    if (
      !didRun.current &&
      typeof callbackRef.current === 'function' &&
      (deadline.timeRemaining() > 0 || deadline.didTimeout)
    ) {
      didRun.current = true;
      callbackRef.current();
    }
  }, []);

  useEffect(() => {
    if (!enabled) {
      return undefined;
    }

    const id = window.requestIdleCallback(handleIdleCallback, {
      timeout,
    });

    return () => {
      window.cancelIdleCallback(id);
    };
  }, [enabled, timeout, handleIdleCallback]);
};

export default useIdleCallback;
