import {
  BASE_PATH_1_PATHNAME,
  DEFAULT_RESUME_BUILDER_BASE_PATH,
  RESUME_BUILDER_INTRO_PATHNAME,
} from '@glass/common/modules/pages/paths';
import {
  BASE_PATH_1_QUERY,
  BASE_PATH_2_QUERY,
  BASE_PATH_3_QUERY,
} from '@glass/common/modules/pages/queryParams';
import filterJoin from '@glass/common/modules/strings/filterJoin';
import getBuilderSectionStepParams from '@glass/web/modules/resumes/router/getBuilderSectionStepParams';
import makeFreeBuilderBasePath from '@glass/web/modules/resumes/router/resumeBuilderBasePath/makeFreeBuilderBasePath';
import { Section } from '@glass/web/modules/resumes/router/sections';
import { metaStepData } from '@glass/web/modules/resumes/router/stepData';
import { INTRO_STEP, Step } from '@glass/web/modules/resumes/router/steps';

export type ResumeBuilderRouterParams = {
  [BASE_PATH_1_QUERY]: string;
  [BASE_PATH_2_QUERY]?: Section;
  [BASE_PATH_3_QUERY]?: Step;
  [key: string]: string | undefined;
};
export default class ResumeBuilderRouter {
  static builderFirstStepParams = getBuilderSectionStepParams({
    [BASE_PATH_2_QUERY]: metaStepData.firstSection,
  });

  // this only supports v1
  static extendDefaultParams = (
    params: ResumeBuilderRouterParams,
    { isFree = false, defaultBuilderBasePath = DEFAULT_RESUME_BUILDER_BASE_PATH } = {},
  ) => {
    let defaultParams = {
      ...params,
      [BASE_PATH_1_QUERY]: params?.[BASE_PATH_1_QUERY] || defaultBuilderBasePath,
    };
    const { [BASE_PATH_2_QUERY]: section, ...otherParams } = defaultParams;

    if (!section) {
      defaultParams = {
        ...ResumeBuilderRouter.builderFirstStepParams, // this is causing the redirection in 2/work-history/summary to redirect to  templates/summary
        ...otherParams,
      };
    }

    if (isFree) {
      defaultParams = {
        ...defaultParams,
        [BASE_PATH_1_QUERY]: makeFreeBuilderBasePath(defaultParams[BASE_PATH_1_QUERY]),
      };
    }

    return defaultParams;
  };

  static getResumeBuilderUrl = (query: ResumeBuilderRouterParams) => {
    const {
      [BASE_PATH_2_QUERY]: section,
      [BASE_PATH_3_QUERY]: step,
      ...otherParams
    } = ResumeBuilderRouter.extendDefaultParams(query);

    if (step === INTRO_STEP) {
      return {
        pathname: RESUME_BUILDER_INTRO_PATHNAME,
        query: {
          [BASE_PATH_2_QUERY]: section,
          ...otherParams,
        },
      };
    }

    if (!section) {
      return {
        pathname: BASE_PATH_1_PATHNAME,
        query: otherParams,
      };
    }

    return {
      pathname: `/[${BASE_PATH_1_QUERY}]/${filterJoin([section, step], '/')}`,
      query: otherParams,
    };
  };
}
