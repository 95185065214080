import { useQuery, TypedDocumentNode, type QueryHookOptions } from '@apollo/client';
import React, { useEffect, useState, useMemo } from 'react';
import { useDebounce } from 'use-debounce';

import Autocomplete, { AutocompleteProps } from '@glass/web/components/Autocomplete/Autocomplete';
import { DEFAULT_DELAY_VALUE } from '@glass/web/components/Autocomplete/constants';
import createFilterOptions, {
  defaultFilterKeys,
} from '@glass/web/modules/autocomplete/createFilterOptions';
import defaultOptionLabel from '@glass/web/modules/autocomplete/getOptionLabel';
import Suggestion from '@glass/web/modules/types/SuggestionInterface';

export interface SearchAutocompleteProps<T>
  extends Omit<AutocompleteProps<T>, 'suggestions' | 'value' | 'getOptionLabel' | 'filterOptions'> {
  value: AutocompleteProps<T>['value'];
  dataKey: string;
  delay?: number;
  disabled?: boolean;
  loading?: boolean;
  maxResults?: number;
  query: TypedDocumentNode;
  queryOptions?: (params: {
    debouncedValue: string;
    disabled?: boolean;
    maxResults?: number;
  }) => QueryHookOptions;
  getOptionLabel?: AutocompleteProps<T>['getOptionLabel'];
  filterKeys?: string[];
  isOnChangeExpectingAString?: boolean;
}

export default function SearchAutocomplete<T extends Suggestion>({
  autoFocus,
  inputRef,
  value,
  query,
  className,
  dataKey,
  textFieldProps,
  textFieldClassName,
  disabled,
  delay,
  maxResults,
  onChange,
  onBlur,
  onFocus,
  onSelectChange,
  getOptionLabel,
  loading: loadingProp,
  queryOptions: queryOptionsProp,
  filterKeys = defaultFilterKeys,
  isOnChangeExpectingAString,
}: SearchAutocompleteProps<T>) {
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [isChildDisabled, setIsChildDisabled] = useState(false);
  const [debouncedValue] = useDebounce(value, delay || DEFAULT_DELAY_VALUE);

  const filterOptions = useMemo(() => createFilterOptions({ keys: filterKeys }), [filterKeys]);
  const isFetchDisabled = disabled || isChildDisabled;

  const queryOptions: QueryHookOptions = useMemo(
    () =>
      queryOptionsProp && typeof queryOptionsProp === 'function'
        ? queryOptionsProp({ debouncedValue, disabled: isFetchDisabled, maxResults })
        : {
            skip: isFetchDisabled || !debouncedValue,
            variables: {
              search: debouncedValue,
              options: {
                first: maxResults,
              },
            },
          },
    [debouncedValue, isFetchDisabled, maxResults, queryOptionsProp],
  );

  const { data, loading: isLoading } = useQuery<{
    [dataKey: string]: [];
  }>(query, queryOptions);

  useEffect(() => {
    if (!isLoading && data?.[dataKey]) {
      setSuggestions(data?.[dataKey]);
    }
  }, [dataKey, data, isLoading]);

  return (
    <Autocomplete
      isOnChangeExpectingAString={isOnChangeExpectingAString}
      className={className}
      disabled={disabled}
      inputRef={inputRef}
      getOptionLabel={getOptionLabel || defaultOptionLabel}
      filterOptions={filterOptions}
      loading={isLoading || loadingProp}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onSelectChange={onSelectChange}
      suggestions={suggestions}
      textFieldClassName={textFieldClassName}
      textFieldProps={textFieldProps}
      value={value}
      autoFocus={autoFocus}
      onDisableSearch={setIsChildDisabled}
    />
  );
}
