import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

import { LocaleType } from '@glass/env/modules/locales/constants';
import getLocaleOrigin from '@glass/env/modules/locales/getLocaleOrigin';
import getLocalePath from '@glass/env/modules/locales/getLocalePath';

function HrefLangHead() {
  const { locale: currentLocale, locales, defaultLocale, domainLocales } = useRouter();

  if (!currentLocale || !locales) {
    return null;
  }

  return (
    <Head>
      {locales.map((locale) => {
        if (locale === currentLocale || !defaultLocale) {
          return null;
        }

        const href = `${getLocaleOrigin(locale as LocaleType)}${
          getLocalePath(locale as LocaleType, {
            defaultLocale,
            domains: domainLocales,
          }) || ''
        }`;

        return <link key={locale} href={href} hrefLang={locale} rel="alternate" />;
      })}
    </Head>
  );
}

export default React.memo(HrefLangHead);
