import PropTypes from 'prop-types';
import React from 'react';

import { HEADER_CTA } from '@glass/common/modules/tests/testIds';
import { CTA_BUTTON_ID } from '@glass/web/modules/content/pages/contentIds';
import { SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import Button from '@glass/shared/components/Button/Button';
import ResumeBuilderLink from '@glass/shared/components/Link/ResumeBuilderLink/ResumeBuilderLink';
import withTrackingContext from '@glass/shared/modules/tracking/withTrackingContext';

const ButtonWithTrackingContext = withTrackingContext(Button, CTA_BUTTON_ID);

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down(SM)]: {
      letterSpacing: 0,
    },
  },
  label: {
    display: 'initial',
  },
}));

function HeaderCtaButton({ className, disabled, onClick, children }) {
  const { classes } = useStyles();
  return (
    <ButtonWithTrackingContext
      className={className}
      classes={classes}
      color="primary"
      component={ResumeBuilderLink}
      data-test-id={HEADER_CTA}
      disabled={disabled}
      size="medium"
      variant="contained"
      onClick={onClick}
    >
      {children}
    </ButtonWithTrackingContext>
  );
}

HeaderCtaButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default React.memo(HeaderCtaButton);
