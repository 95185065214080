import { useMemo } from 'react';

import {
  READY_STATE_ORDER,
  useDocumentReadyState,
  ReadyState,
} from '@glass/web/modules/loading/withPriorityEvents';

const LAST_READY_STATE = READY_STATE_ORDER[READY_STATE_ORDER.length - 1];

// used for process intensive lazy loading (new script, react code)
const useIsDocumentReadyState = (desiredReadyState: ReadyState = LAST_READY_STATE) => {
  const documentReadyState = useDocumentReadyState();

  return useMemo(
    () =>
      documentReadyState === LAST_READY_STATE || // shortcut
      READY_STATE_ORDER.indexOf(documentReadyState) >= READY_STATE_ORDER.indexOf(desiredReadyState),
    [documentReadyState, desiredReadyState],
  );
};

export default useIsDocumentReadyState;
