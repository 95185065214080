import React from 'react';

import { SM } from '@glass/web/modules/theme/breakpointKeys';
import makeStyles from '@glass/web/modules/theme/makeStyles';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: 56,
    [theme.breakpoints.up(SM)]: {
      height: 64,
    },
  },
}));

function HeaderSpacer() {
  const { classes } = useStyles();
  return <div className={classes.root} />;
}

export default React.memo(HeaderSpacer);
