import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectZendeskLoaded } from '@glass/shared/modules/zendesk/zendeskReducer';

const useZendesk = ({ show = false }) => {
  const isZendeskLoaded = useSelector(selectZendeskLoaded);
  useEffect(() => {
    if (isZendeskLoaded) {
      window.zE('webWidget', show ? 'show' : 'hide');
    }
  }, [isZendeskLoaded, show]);
};

export default useZendesk;
